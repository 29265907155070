import { Injectable } from '@angular/core';
import { ErrorService } from '@interacta-shared/data-access-error';
import { TipService } from '@interacta-shared/feature-tip';
import { flatten, getNextPageToken, isDefined } from '@interacta-shared/util';
import { PostType } from '@modules/post/models/base-post.model';
import { ICustomPost } from '@modules/post/models/custom-post.model';
import { AttachmentService } from '@modules/post/services/attachment.service';
import { TaskService } from '@modules/tasks/services/task.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, from, of } from 'rxjs';
import {
    catchError,
    concatMap,
    exhaustMap,
    expand,
    filter,
    groupBy,
    map,
    mergeMap,
    switchMap,
    tap,
    toArray,
    withLatestFrom,
} from 'rxjs/operators';
import { selectPostById } from '../post/post.selectors';
import * as fapi from './task-api.actions';
import * as fa from './task.actions';
import { selectTaskById, selectTasksByPostId } from './task.selectors';

@Injectable()
export class TaskEffects {
    fetchTasks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fa.fetchTasks),
            switchMap((action) =>
                of(action).pipe(
                    withLatestFrom(
                        this.store
                            .select(selectPostById(action.postId))
                            .pipe(
                                filter(
                                    (post): post is ICustomPost =>
                                        isDefined(post) &&
                                        post.type === PostType.CUSTOM,
                                ),
                            ),
                    ),
                ),
            ),
            switchMap(([{ postId, filters }, post]) =>
                this.taskService.getPostTasks(post, filters).pipe(
                    map((tasks) =>
                        fapi.fetchTasksSuccess({ postId, tasks, filters }),
                    ),
                    catchError((error) =>
                        of(fapi.fetchTasksError({ postId, error })),
                    ),
                ),
            ),
        ),
    );

    fetchTasksUntilId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fa.fetchTasksUntilId),
            switchMap((action) =>
                of(action).pipe(
                    withLatestFrom(
                        this.store
                            .select(selectPostById(action.postId))
                            .pipe(
                                filter(
                                    (post): post is ICustomPost =>
                                        isDefined(post) &&
                                        post.type === PostType.CUSTOM,
                                ),
                            ),
                    ),
                ),
            ),
            switchMap(([{ postId, filters, taskId }, post]) =>
                this.taskService.getPostTasks(post, filters).pipe(
                    expand((res) =>
                        getNextPageToken(res.nextPageToken) &&
                        !res.list.find((c) => c.id === taskId)
                            ? this.taskService.getPostTasks(post, {
                                  ...filters,
                                  pageToken: getNextPageToken(
                                      res.nextPageToken,
                                  ),
                              })
                            : EMPTY,
                    ),
                    toArray(),
                    map((responses) => ({
                        ...responses[responses.length - 1],
                        list: flatten(responses.map((r) => r.list)),
                    })),

                    map((tasks) =>
                        fapi.fetchTasksSuccess({ postId, tasks, filters }),
                    ),
                    catchError((error) =>
                        of(fapi.fetchTasksError({ postId, error })),
                    ),
                ),
            ),
        ),
    );

    fetchTasksPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fa.fetchTasksPage),
            switchMap((action) =>
                of(action).pipe(
                    withLatestFrom(
                        this.store.select(selectTasksByPostId(action.postId)),
                        this.store
                            .select(selectPostById(action.postId))
                            .pipe(
                                filter(
                                    (post): post is ICustomPost =>
                                        isDefined(post) &&
                                        post.type === PostType.CUSTOM,
                                ),
                            ),
                    ),
                ),
            ),
            filter(([_, taskList]) => isDefined(taskList)),
            switchMap(([{ postId }, taskList, post]) =>
                this.taskService
                    .getPostTasks(post, {
                        ...taskList.filter,
                        pageToken: getNextPageToken(taskList.nextPageToken),
                    })
                    .pipe(
                        map((tasks) =>
                            fapi.fetchTasksPageSuccess({ postId, tasks }),
                        ),
                        catchError((error) =>
                            of(fapi.fetchTasksPageError({ postId, error })),
                        ),
                    ),
            ),
        ),
    );

    fetchTaskForEdit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fa.fetchTaskForEdit),
            concatMap(({ taskId }) =>
                this.taskService.getTaskForEdit(taskId).pipe(
                    map((editTask) =>
                        fapi.fetchTaskForEditSuccess({
                            taskId,
                            task: editTask,
                        }),
                    ),
                    catchError((error) =>
                        of(fapi.fetchTaskForEditError({ taskId, error })),
                    ),
                ),
            ),
        ),
    );

    fetchTaskAttachments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fa.fetchTaskAttachments),
            switchMap((action) =>
                this.store.select(selectTasksByPostId(action.postId)),
            ),
            switchMap((tasks) =>
                from(tasks.list).pipe(
                    concatMap((task) =>
                        this.attachmentService
                            .loadTaskAttachments(task.id!, {
                                calculateTotalItemsCount: true,
                            })
                            .pipe(
                                map((attachments) =>
                                    fapi.fetchTaskAttachmentsSuccess({
                                        taskId: task.id!,
                                        attachments,
                                    }),
                                ),
                                catchError((error) =>
                                    of(
                                        fapi.fetchTaskAttachmentsError({
                                            taskId: task.id!,
                                            error,
                                        }),
                                    ),
                                ),
                            ),
                    ),
                ),
            ),
        ),
    );

    createTask$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fa.createTask),
            exhaustMap(({ postId, task }) =>
                this.taskService.createTask(postId, task).pipe(
                    map((createdTask) =>
                        fapi.createTaskSuccess({
                            postId,
                            task: createdTask,
                            message: 'DETAIL.TASK.TIP_SUCCESSFULLY_CREATED',
                        }),
                    ),
                    catchError((error) =>
                        of(fapi.createTaskError({ postId, error })),
                    ),
                ),
            ),
        ),
    );

    editTask$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fa.editTask),
            exhaustMap(({ postId, task }) =>
                this.taskService.editTask(task).pipe(
                    map((createdTask) =>
                        fapi.editTaskSuccess({
                            postId,
                            task: createdTask,
                            message: 'DETAIL.TASK.TIP_SUCCESSFULLY_SAVED',
                        }),
                    ),
                    catchError((error) =>
                        of(fapi.editTaskError({ postId, error })),
                    ),
                ),
            ),
        ),
    );

    copyTask$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fa.copyTask),
            exhaustMap(({ postId, copyId, task }) =>
                this.taskService.copyTask(copyId, task).pipe(
                    map((copiedTask) =>
                        fapi.copyTaskSuccess({
                            postId,
                            task: copiedTask,
                            message: 'DETAIL.TASK.TIP_SUCCESSFULLY_CREATED',
                        }),
                    ),
                    catchError((error) =>
                        of(fapi.copyTaskError({ postId, error })),
                    ),
                ),
            ),
        ),
    );

    deleteTask$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fa.deleteTask),
            exhaustMap(({ postId, taskId }) =>
                this.taskService.deleteTask(taskId).pipe(
                    map((_) =>
                        fapi.deleteTaskSuccess({
                            postId,
                            taskId,
                            message: 'DETAIL.TASK.TIP_SUCCESSFULLY_DELETED',
                        }),
                    ),
                    catchError((error) =>
                        of(fapi.deleteTaskError({ postId, error })),
                    ),
                ),
            ),
        ),
    );

    refetchTasksAfterOperationSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                fapi.createTaskSuccess,
                fapi.editTaskSuccess,
                fapi.deleteTaskSuccess,
                fapi.copyTaskSuccess,
                fapi.toggleStateSuccess,
            ),
            concatMap((action) =>
                of(action).pipe(
                    withLatestFrom(
                        this.store.select(selectTasksByPostId(action.postId)),
                    ),
                ),
            ),
            map(([{ postId }, taskList]) =>
                fa.fetchTasks({
                    postId,
                    filters: { ...taskList.filter, pageToken: null },
                }),
            ),
        ),
    );

    showTipOnTaskOperationSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    fapi.createTaskSuccess,
                    fapi.editTaskSuccess,
                    fapi.deleteTaskSuccess,
                    fapi.copyTaskSuccess,
                ),
                tap(
                    ({ message }) =>
                        message && this.tipService.success(message),
                ),
            ),
        { dispatch: false },
    );

    toggleState$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fa.toggleState),
            concatMap((action) =>
                of(action).pipe(
                    withLatestFrom(
                        this.store.select(
                            selectTaskById(action.postId, action.taskId),
                        ),
                    ),
                ),
            ),
            // Relies of the fact that the reducer has already toggled the state
            switchMap(([action, task]) =>
                this.taskService.setStateTask(task, task.state).pipe(
                    map((_) =>
                        fapi.toggleStateSuccess({
                            postId: action.postId,
                            taskId: task.id,
                        }),
                    ),
                    tap((_) => {
                        if (action.showConfirmToast) {
                            this.tipService.success(
                                'DETAIL.TASK.TIP_SUCCESSFULLY_SAVED',
                            );
                        }
                    }),
                    catchError((error) =>
                        of(
                            fapi.toggleStateError({
                                postId: action.postId,
                                taskId: task.id,
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    error$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    fapi.fetchTasksError,
                    fapi.fetchTasksPageError,
                    fapi.createTaskError,
                    fapi.editTaskError,
                    fapi.deleteTaskError,
                    fapi.toggleStateError,
                    fapi.fetchTaskForEditError,
                ),
                tap(({ error }) => {
                    this.errorService.handle(error);
                }),
            ),
        { dispatch: false },
    );

    toggleSubtaskState$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fa.toggleSubtaskState),
            groupBy((action) => action.task.id),
            mergeMap((groupBy) =>
                groupBy.pipe(
                    exhaustMap(({ postId, task, subtaskId, state }) =>
                        this.taskService
                            .setSubTaskState(task, subtaskId, state)
                            .pipe(
                                map((subtask) =>
                                    fapi.toggleSubtaskStateSuccess({
                                        postId,
                                        taskId: task.id,
                                        subtask,
                                    }),
                                ),
                                catchError((error) =>
                                    of(
                                        fapi.toggleSubtaskStateError({
                                            postId,
                                            taskId: task.id,
                                            subtaskId,
                                            state,
                                            error,
                                        }),
                                    ),
                                ),
                            ),
                    ),
                ),
            ),
        ),
    );

    editReminders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fa.editReminders),
            concatMap(({ task }) =>
                this.taskService.editTaskReminders(task).pipe(
                    map((task) => fapi.editRemindersSuccess({ task })),
                    catchError((error) =>
                        of(fapi.editRemindersError({ task, error })),
                    ),
                ),
            ),
        ),
    );

    editWatchers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fa.editWatchers),
            groupBy((action) => action.taskId),
            mergeMap((groupBy) =>
                groupBy.pipe(
                    mergeMap(
                        ({
                            taskId,
                            postId,
                            addWatcherUserIds,
                            removeWatcherUserIds,
                            addWatcherGroupIds,
                            removeWatcherGroupIds,
                        }) =>
                            this.taskService
                                .editTaskWatchers(
                                    taskId,
                                    addWatcherUserIds,
                                    removeWatcherUserIds,
                                    addWatcherGroupIds,
                                    removeWatcherGroupIds,
                                )
                                .pipe(
                                    map((task) => ({ ...task, postId })),
                                    map((task) =>
                                        fapi.editWatchersSuccess({
                                            task: task,
                                        }),
                                    ),
                                    catchError((error) =>
                                        of(fapi.editWatchersError(error)),
                                    ),
                                ),
                    ),
                ),
            ),
        ),
    );

    constructor(
        private store: Store,
        private actions$: Actions,
        private taskService: TaskService,
        private tipService: TipService,
        private errorService: ErrorService,
        private attachmentService: AttachmentService,
    ) {}
}
