import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    CalculateItemsCount,
    ConfigurationService,
} from '@interacta-shared/data-access-configuration';
import {
    RegularLoading,
    assertUnreachable,
    emptyPaginatedList,
    filterMap,
    flatten,
    getNextPageToken,
    isDefined,
    mapArrayById,
    paginatedListFromArray,
    unique,
} from '@interacta-shared/util';
import {
    dashboardCommunity,
    dashboardRoutes,
} from '@modules/app-routing/routes';
import {
    ICommunity,
    ICommunityTree,
} from '@modules/communities/models/communities.model';
import { CustomFieldType } from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { AllPostsExternalReferencesService } from '@modules/communities/services/all-posts-external-references.service';
import { CloudTranslationService } from '@modules/communities/services/cloud-translation.service';
import { CommunitiesService } from '@modules/communities/services/communities.service';
import { WorkflowService } from '@modules/communities/services/workflow.service';
import * as PostSelectors from '@modules/communities/store/post/post.selectors';
import { IUserAction } from '@modules/core';

import { AuthService } from '@interacta-shared/data-access-auth';
import {
    CustomError,
    ErrorService,
    hasRequiredFieldValidationError,
} from '@interacta-shared/data-access-error';
import { TipService, openProTip } from '@interacta-shared/feature-tip';
import {
    HistoryRoutingService,
    LoadingLayerService,
    downloadBlob,
} from '@interacta-shared/util-common';
import { FilterCommunitiesService } from '@modules/communities/services/filter-communities.service';
import { zipWith } from '@modules/core/helpers/generic.utils';
import { TimeoutsService } from '@modules/core/services/timeouts.service';
import { AppSelectors } from '@modules/core/store';
import { AppRouterState } from '@modules/core/store/app-router.serializer';
import { PostSelectionService } from '@modules/dashboard/services/post-selection.service';
import { isFeedbackPostInProcessingStatus } from '@modules/feedback/models/feedback.utils';
import { AttachmentCategoryType } from '@modules/post/models/attachment/attachment.model';
import {
    IBasePost,
    IBasePostCreate,
    IBasePostEdit,
    IPostMetadata,
    PostType,
    VisibilityType,
} from '@modules/post/models/base-post.model';
import {
    ICustomPostCreate,
    ICustomPostEdit,
} from '@modules/post/models/custom-post.model';
import {
    EventParticipateType,
    IEventPostCreate,
    IEventPostEdit,
    isEventPost,
} from '@modules/post/models/event-post.model';
import { AttachmentEntityType } from '@modules/post/models/filter-attachment.model';
import {
    IOrder,
    IPostFilters,
    PostOrderType,
} from '@modules/post/models/filter-post/filter-post.model';
import {
    emptyPostFilters,
    filterIsEmpty,
    filtersAreEquals,
} from '@modules/post/models/filter-post/filter-post.utils';
import {
    GenericPost,
    GenericPostCreate,
} from '@modules/post/models/generic-post.model';
import { IEditPostLinksResponse } from '@modules/post/models/post-link/post-link.model';
import { DashboardView, PostList } from '@modules/post/models/post-list.model';
import {
    SurveyPostCreate,
    SurveyPostEdit,
    SurveyType,
} from '@modules/post/models/survey-post/survey-post.model';
import {
    isSurveyPost,
    setSurveyStateUpdateTimeout,
} from '@modules/post/models/survey-post/survey-post.utils';
import { AttachmentService } from '@modules/post/services/attachment.service';
import { CustomPostService } from '@modules/post/services/custom-post.service';
import { EventPostService } from '@modules/post/services/event-post.service';
import { PostListService } from '@modules/post/services/post-list.service';
import { PostService } from '@modules/post/services/post.service';
import { SurveyPostService } from '@modules/post/services/survey-post.service';
import { PostPreviewActions } from '@modules/post/store/preview';
import { setEventStateUpdateTimeout } from '@modules/post/utils/event-post.utils';
import { CacheMap, CommunityUI } from '@modules/state/models/ui-state.model';
import { CatalogsStateService } from '@modules/state/services/catalogs-state.service';
import { CommunitiesStateService } from '@modules/state/services/communities-state.service';
import { StateService } from '@modules/state/services/state.service';
import { emptyTaskFilters } from '@modules/tasks/models/filter-task.model';
import { ITaskType } from '@modules/tasks/models/task.model';
import { SurveyTaskService } from '@modules/tasks/services/survey-task.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    EMPTY,
    Observable,
    concat,
    forkJoin,
    from,
    iif,
    merge,
    of,
    throwError,
} from 'rxjs';
import {
    catchError,
    concatMap,
    defaultIfEmpty,
    exhaustMap,
    expand,
    filter,
    finalize,
    first,
    groupBy,
    map,
    mergeMap,
    pairwise,
    switchMap,
    takeUntil,
    tap,
    toArray,
    withLatestFrom,
} from 'rxjs/operators';
import {
    CommunityApiActions,
    PostActions,
    PostActionsApi,
    TaskActions,
} from '..';
import * as fromActionsApi from './post-api.actions';
import * as fromActions from './post.actions';
import { AppState, PostFetch } from './post.reducer';
import {
    selectAllFetchInfos,
    selectComments,
    selectCurrentFilters,
    selectLoadingInfo,
    selectPostById,
} from './post.selectors';

function mGroupBy<T1, Key, T2>(
    source$: Observable<T1>,
    keySelector: (value: T1) => Key,
    operator: (x: Observable<T1>) => Observable<T2>,
) {
    return source$.pipe(
        groupBy(keySelector),
        mergeMap((group) => group.pipe(operator)),
    );
}

function groupByPostId<T1 extends { postId: number }, T2>(
    source$: Observable<T1>,
    operator: (x: Observable<T1>) => Observable<T2>,
) {
    return mGroupBy(source$, ({ postId }) => postId, operator);
}

function getPostAttachmentsOrder(entityTypes: AttachmentEntityType[]): IOrder {
    const orderByEntityType: AttachmentEntityType[] = [
        'postFilePicker',
        'screenFilePicker',
    ];
    return {
        orderBy: entityTypes.some((t) => orderByEntityType.includes(t))
            ? 'entityType'
            : 'creationTimestamp',
        orderDesc: false,
    };
}

@Injectable()
export class PostEffects {
    willMarkAsViewed$ = createEffect(() =>
        merge(
            this.actions$.pipe(
                ofType(fromActionsApi.fetchPostsSuccess),
                filter((action) => action.fetchType === 'detail'),
                map((action) => ({ postId: action.postList.list[0].id })),
            ),
            this.actions$.pipe(
                ofType(
                    fromActionsApi.workflowTransitionSuccess,
                    PostPreviewActions.fetchPostSuccess,
                ),
                map((action) => ({ postId: action.post.id })),
            ),
            this.actions$.pipe(
                ofType(fromActionsApi.editSuccess),
                map((action) => ({ postId: action.editedPost.id })),
            ),
            this.actions$.pipe(
                ofType(
                    fromActionsApi.likeToggleSuccess,
                    fromActionsApi.followToggleSuccess,
                    fromActionsApi.participateEventSuccess,
                    fromActionsApi.visibilityChangeSuccess,
                    fromActionsApi.fetchViewersSuccess,
                    fromActionsApi.fetchLikersSuccess,
                    fromActionsApi.fetchParticipantsSuccess,
                    fromActionsApi.confirmAcknowledgeTaskSuccess,
                    fromActionsApi.addCommentSuccess,
                    fromActionsApi.addStreamingCommentSuccess,
                ),
            ),
        ).pipe(map(({ postId }) => fromActions.markAsViewed({ postId }))),
    );

    markAsViewed$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.markAsViewed),
            groupBy(({ postId }) => postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    exhaustMap(({ postId }) => {
                        const currUser = this.authService.getCurrentUserData();

                        if (!currUser) {
                            return throwError(
                                'The user must be logged in order to markAsViewed',
                            );
                        }

                        return this.postService.markPostAsViewed(postId).pipe(
                            map(({ viewersCount, viewedTimestamp }) =>
                                fromActionsApi.markAsViewedSuccess({
                                    postId,
                                    viewersCount,
                                    viewedTimestamp,
                                    currUser,
                                }),
                            ),
                            catchError((error) =>
                                of(
                                    fromActionsApi.markAsViewedError({
                                        postId,
                                        error,
                                    }),
                                ),
                            ),
                        );
                    }),
                ),
            ),
        ),
    );

    notificationError$ = createEffect(
        () =>
            merge(
                this.actions$.pipe(
                    ofType(
                        fromActionsApi.fetchPostsPageError,
                        fromActionsApi.likeToggleError,
                        fromActionsApi.followToggleError,
                        fromActionsApi.participateEventError,
                        fromActionsApi.visibilityChangeError,
                        fromActionsApi.addWatchersError,
                        fromActionsApi.removeWatchersError,
                        fromActionsApi.fetchCommentsError,
                        fromActionsApi.fetchStreamingCommentsError,
                        fromActionsApi.reloadError,
                        fromActionsApi.fetchViewersError,
                        fromActionsApi.fetchLikersError,
                        fromActionsApi.fetchParticipantsError,
                        fromActionsApi.deletePostError,
                        fromActionsApi.massiveDeletePostsError,
                        fromActionsApi.fetchLinkedPostsError,
                        fromActionsApi.fetchLinksAndRelationsCountError,
                        fromActionsApi.removePostsFromRelationsError,
                        fromActionsApi.fetchCommentLikersError,
                        fromActionsApi.deleteAttachmentsError,
                        fromActionsApi.fetchAttachmentsError,
                        fromActionsApi.fetchAttachmentsPageError,
                        fromActionsApi.createError,
                        fromActionsApi.editError,
                        fromActionsApi.copyError,
                        fromActionsApi.workflowTransitionError,
                        fromActionsApi.editWorkflowScreenError,
                        fromActionsApi.fetchInvitationsListError,
                        fromActionsApi.fetchGroupInvitationsListError,
                        CommunityApiActions.fetchQuickFiltersError,
                        fromActionsApi.fetchTasksAssigneesListError,
                        fromActionsApi.closeSurveyError,
                        fromActionsApi.fetchPostsCapabilitiesError,
                    ),
                ),
                this.actions$.pipe(
                    ofType(fromActionsApi.fetchPostsError),
                    filter(({ error }) => error.status !== 403),
                ),
            ).pipe(tap(({ error }) => this.errorService.handle(error))),
        { dispatch: false },
    );

    addFilters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.addFilters),
            concatMap((action) =>
                of(action).pipe(
                    withLatestFrom(
                        this.store.select(
                            selectCurrentFilters(action.fetchType),
                        ),
                    ),
                ),
            ),
            map(([{ addFilters, fetchType }, currFilters]) =>
                fromActions.changeFilters({
                    fetchType,
                    updatedFilters: {
                        ...currFilters,
                        ...addFilters,
                    },
                }),
            ),
        ),
    );

    removeFilters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.removeFilters),
            concatMap((action) =>
                of(action).pipe(
                    withLatestFrom(
                        this.store.select(
                            selectCurrentFilters(action.fetchType),
                        ),
                    ),
                ),
            ),
            map(
                ([
                    { fetchType, removeFilters, clearPreviousFilter },
                    currFilters,
                ]) => {
                    const nextFilters = { ...currFilters };
                    for (const filterToRemove of removeFilters) {
                        delete nextFilters[filterToRemove];
                    }
                    return fromActions.changeFilters({
                        fetchType,
                        updatedFilters: nextFilters,
                        clearPreviousFilter,
                    });
                },
            ),
        ),
    );

    filterChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.changeFilters),
            concatMap((action) =>
                of(action).pipe(
                    withLatestFrom(
                        this.store.select(
                            selectCurrentFilters(action.fetchType),
                        ),
                        this.store.select(selectLoadingInfo(action.fetchType)),
                        this.stateService.uiState.communitiesUI.onDataChange(),
                    ),
                ),
            ),
            filter(
                ([
                    { updatedFilters, forcePostFetch },
                    previousFilters,
                    loadingInfo,
                    communitiesUIMap,
                ]) => {
                    const areFiltersEquals = filtersAreEquals(
                        updatedFilters,
                        previousFilters,
                    );
                    const isHomeOrPostView = this.isHomeOrPostView(
                        updatedFilters.communityId,
                        communitiesUIMap,
                    );
                    return (
                        !areFiltersEquals ||
                        forcePostFetch ||
                        previousFilters.communityId !==
                            updatedFilters.communityId ||
                        (isHomeOrPostView &&
                            loadingInfo.pageTokenInfo.tag === 'firstLoading' &&
                            !loadingInfo.isFetching)
                    );
                },
            ),
            switchMap(([action, _]) =>
                this.stateService.communitiesState.communityTree$.pipe(
                    first(),
                    filter(isDefined),
                    map((tree): [typeof action, ICommunityTree] => [
                        action,
                        tree,
                    ]),
                ),
            ),
            tap(([{ updatedFilters }, communityTree]) => {
                const community = communityTree?.communityList.find(
                    (c) => c.id === updatedFilters.communityId,
                );
                this.filterCommunitiesService.setFilterPostListCache(
                    community?.id ?? 'dashboard',
                    {
                        ...emptyPostFilters(),
                        ...updatedFilters,
                    },
                );
            }),
            map(([action]) =>
                fromActionsApi.changeFiltersSuccess({
                    ...action,
                }),
            ),
        ),
    );

    fetchOnFilterChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActionsApi.changeFiltersSuccess),
            concatMap((action) =>
                of(action).pipe(
                    withLatestFrom(
                        this.stateService.uiState.communitiesUI.onDataChange(),
                        this.store.select(AppSelectors.selectRouteState),
                    ),
                ),
            ),
            filter(
                ([{ updatedFilters }, communitiesUIMap, routeState]) =>
                    isDefined(routeState) &&
                    dashboardRoutes.includes(routeState.appRoute) &&
                    this.isHomeOrPostView(
                        updatedFilters.communityId,
                        communitiesUIMap,
                    ),
            ),
            map(([{ fetchType, updatedFilters }, _]) =>
                fromActions.fetchPosts({
                    fetchType,
                    filters: updatedFilters,
                }),
            ),
        ),
    );

    clearPostSelectionOnFilterChange$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActionsApi.changeFiltersSuccess),
                tap(() => this.postSelectionService.clearSelection()),
            ),
        { dispatch: false },
    );

    showTipOnChangeFiltersSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActionsApi.changeFiltersSuccess),
            map(({ showTip }) => showTip),
            filterMap((showTip) => showTip),
            map((showTip) => openProTip(showTip)),
        ),
    );

    fetchPosts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.fetchPosts),
            groupBy((action) => action.fetchType),
            mergeMap((groupById) =>
                groupById.pipe(
                    switchMap(
                        ({
                            fetchType,
                            filters,
                            postId,
                            skipComments,
                            skipMentions,
                        }) =>
                            this.fetchPosts(
                                fetchType,
                                filters,
                                postId,
                                skipMentions,
                            ).pipe(
                                map((postList) => ({
                                    ...postList,
                                    list: postList.list.map((p) =>
                                        this.translationService.getTranslatedPostFromCache(
                                            p,
                                        ),
                                    ),
                                })),
                                map((postList) =>
                                    fromActionsApi.fetchPostsSuccess({
                                        fetchType,
                                        postList,
                                        filters,
                                        skipComments,
                                    }),
                                ),
                                catchError((error) =>
                                    of(
                                        fromActionsApi.fetchPostsError({
                                            fetchType,
                                            error,
                                            postId,
                                        }),
                                    ),
                                ),
                            ),
                    ),
                ),
            ),
        ),
    );

    fetchPostsCount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.fetchPostsCount),
            groupBy((action) => action.fetchType),
            mergeMap((groupById) =>
                groupById.pipe(
                    switchMap(({ fetchType, filters }) =>
                        this.fetchPostsCount(filters).pipe(
                            map((totalCount) =>
                                fromActionsApi.fetchPostsCountSuccess({
                                    fetchType,
                                    totalCount,
                                }),
                            ),
                            catchError((error) =>
                                of(
                                    fromActionsApi.fetchPostsCountError({
                                        fetchType,
                                        error,
                                    }),
                                ),
                            ),
                        ),
                    ),
                ),
            ),
        ),
    );

    updateSurveyQuestionsDefinitionOnFetchPosts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                fromActionsApi.fetchPostsSuccess,
                fromActionsApi.fetchPostsPageSuccess,
            ),
            groupBy((action) => action.fetchType),
            mergeMap((groupById) =>
                groupById.pipe(
                    map(({ postList }) => postList.list),
                    map((posts) =>
                        fromActions.updateSurveyQuestionsDefinitionPosts({
                            posts,
                        }),
                    ),
                ),
            ),
        ),
    );

    updateSurveyQuestionsDefinitionPosts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.updateSurveyQuestionsDefinitionPosts),
            map(({ posts }) =>
                flatten(
                    posts.map((p) => {
                        if (isSurveyPost(p)) {
                            return p.fieldDefinitions
                                .filter(
                                    (f) =>
                                        f.customFieldType ===
                                        CustomFieldType.CATALOG,
                                )
                                .map((f) => ({
                                    postId: p.id,
                                    fieldId: f.id,
                                    catalogId: f.metadata.catalog_id!,
                                }));
                        } else {
                            return [];
                        }
                    }),
                ),
            ),
            filter((catalogs) => !!catalogs.length),
            mergeMap((postWithCatalogs) => {
                const catalogIds = unique(
                    postWithCatalogs.map((c) => c.catalogId),
                );
                return this.catalogsStateService.getCatalogs(catalogIds).pipe(
                    map((catalogs) =>
                        postWithCatalogs.map((p) => ({
                            postId: p.postId,
                            fieldId: p.fieldId,
                            enumValues: catalogs.find(
                                (c) => p.catalogId === c.id,
                            )?.entries,
                        })),
                    ),
                );
            }),
            concatMap((catalogs) =>
                from(
                    catalogs.map((catalog) =>
                        fromActions.updateSurveyQuestionsDefinition({
                            postId: catalog.postId,
                            fieldId: catalog.fieldId,
                            enumValues: catalog.enumValues,
                        }),
                    ),
                ),
            ),
        ),
    );

    closeSurvey$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.closeSurvey),
            switchMap(({ postId, occToken, surveyType }) =>
                this.surveyPostService.closeSurvey(postId, occToken).pipe(
                    map(({ closedTimestamp, nextOccToken }) =>
                        fromActionsApi.closeSurveySuccess({
                            postId,
                            closedTimestamp,
                            nextOccToken,
                            surveyType,
                        }),
                    ),
                    catchError((error) =>
                        of(
                            fromActionsApi.closeSurveyError({
                                postId,
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    closeSurveySuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActionsApi.closeSurveySuccess),
                tap(({ surveyType }) =>
                    this.tipService.success(
                        surveyType == SurveyType.SURVEY
                            ? 'SURVEY.CLOSE_SURVEY_SUCCESS'
                            : 'FEEDBACK.CLOSE_FEEDBACK_SUCCESS',
                    ),
                ),
            ),
        { dispatch: false },
    );

    registerRelatedStateUpdateTimeout$ = createEffect(() =>
        merge(
            this.actions$.pipe(
                ofType(
                    fromActionsApi.fetchPostsSuccess,
                    fromActionsApi.fetchPostsPageSuccess,
                ),
                mergeMap(({ postList }) => postList.list),
            ),
            this.actions$.pipe(
                ofType(fromActions.updateAllPosts),
                mergeMap(({ posts }) => posts),
            ),
        ).pipe(
            filterMap((post) =>
                isSurveyPost(post) || isEventPost(post) ? post : undefined,
            ),
            map((post) =>
                fromActions.setTimeRelatedStateUpdateTimeout({ post }),
            ),
        ),
    );

    setTimeRelatedStateUpdateTimeout$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActions.setTimeRelatedStateUpdateTimeout),
                tap(({ post }) => {
                    if (isSurveyPost(post)) {
                        setSurveyStateUpdateTimeout(
                            post,
                            this.timeoutsService,
                            this.store,
                        );
                    } else if (isEventPost(post)) {
                        setEventStateUpdateTimeout(
                            post,
                            this.timeoutsService,
                            this.store,
                        );
                    }
                }),
            ),
        { dispatch: false },
    );

    fetchPostsWithCurrentFilters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.fetchPostsWithCurrentFilters),
            groupBy((action) => action.fetchType),
            mergeMap((groupById) =>
                groupById.pipe(
                    concatMap((action) =>
                        of(action).pipe(
                            withLatestFrom(
                                this.store.select(
                                    selectCurrentFilters(action.fetchType),
                                ),
                            ),
                        ),
                    ),
                    map(([action, filters]) =>
                        fromActions.fetchPosts({
                            fetchType: action.fetchType,
                            filters,
                            postId: action.postId,
                        }),
                    ),
                ),
            ),
        ),
    );

    requestFetchPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.requestFetchPostsPage),
            groupBy((action) => action.fetchType),
            mergeMap((groupById) =>
                groupById.pipe(
                    concatMap((action) =>
                        of(action).pipe(
                            withLatestFrom(
                                this.store.select(
                                    selectLoadingInfo(action.fetchType),
                                ),
                                this.store.select(
                                    selectCurrentFilters(action.fetchType),
                                ),
                            ),
                        ),
                    ),
                    filter(
                        ([_, loadingInfo, __]) =>
                            !loadingInfo.isFetching &&
                            loadingInfo.pageTokenInfo.tag === 'regularLoading',
                    ),
                    map(([{ fetchType, pageSize }, loadingInfo, filters]) =>
                        fromActions.fetchPostsPage({
                            fetchType,
                            filters: {
                                ...filters,
                                pageSize,
                                pageToken: (
                                    loadingInfo.pageTokenInfo as RegularLoading
                                ).nextPageToken,
                            },
                        }),
                    ),
                ),
            ),
        ),
    );

    fetchPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.fetchPostsPage),
            groupBy((action) => action.fetchType),
            mergeMap((groupById) =>
                groupById.pipe(
                    exhaustMap(({ fetchType, filters }) =>
                        this.fetchPosts(fetchType, filters).pipe(
                            takeUntil(
                                this.actions$.pipe(
                                    ofType(fromActions.fetchPosts),
                                ),
                            ),
                            map((postList) => ({
                                ...postList,
                                list: postList.list.map((p) =>
                                    this.translationService.getTranslatedPostFromCache(
                                        p,
                                    ),
                                ),
                            })),
                            map((postList) =>
                                fromActionsApi.fetchPostsPageSuccess({
                                    fetchType,
                                    postList,
                                }),
                            ),
                            catchError((error) =>
                                of(
                                    fromActionsApi.fetchPostsPageError({
                                        fetchType,
                                        error,
                                    }),
                                ),
                            ),
                        ),
                    ),
                ),
            ),
        ),
    );

    fetchCommentsAfterDashboard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                fromActionsApi.fetchPostsSuccess,
                fromActionsApi.fetchPostsPageSuccess,
            ),
            filter(
                (action) =>
                    action.fetchType === 'dashboard' && !action.skipComments,
            ),
            switchMap((action) => action.postList.list),
            switchMap((post) =>
                iif(
                    () => (post?.commentsCount ?? 0) > 0,
                    of(fromActions.fetchComments({ postId: post.id })),
                    EMPTY,
                ),
            ),
        ),
    );

    fetchCommentsAfterDetail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActionsApi.fetchPostsSuccess),
            filter(
                (action) =>
                    action.fetchType === 'detail' && !action.skipComments,
            ),
            switchMap((action) => action.postList.list),
            filter((post) => (post.commentsCount ?? 0) > 0),
            withLatestFrom(
                this.store.select(AppSelectors.selectRouteState),
                this.store.select(PostSelectors.selectAllPosts),
            ),
            filterMap(([p, route, allPosts]) => {
                const post = allPosts[p.id];
                return post ? { post, route } : undefined;
            }),
            map(({ post, route }) => {
                const untilCommentId = route?.queryParams?.commentId;
                return isDefined(untilCommentId)
                    ? fromActions.requestCommentsUntilId({
                          post,
                          parentCommentId: Number(untilCommentId),
                      })
                    : fromActions.fetchComments({
                          postId: post.id,
                          pageSize: 2,
                      });
            }),
        ),
    );

    fetchLinksAndRelationsCountAfterDetail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActionsApi.fetchPostsSuccess),
            filter((action) => action.fetchType === 'detail'),
            filter(
                (action) => action.postList.list[0].type === PostType.CUSTOM,
            ),
            map((action) =>
                fromActions.fetchLinksAndRelationsCount({
                    postId: action.postList.list[0].id,
                }),
            ),
        ),
    );

    toggleLike$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.likeToggle),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    concatMap((action) =>
                        of(action).pipe(
                            withLatestFrom(
                                this.store.select(
                                    selectPostById(action.postId),
                                ),
                            ),
                        ),
                    ),
                    concatMap(([{ postId, currUser }, post]) =>
                        iif(
                            () => !!post?.likedByMe,
                            this.postService.addPostLike(postId),
                            this.postService.removePostLike(postId),
                        ).pipe(
                            map((_) =>
                                fromActionsApi.likeToggleSuccess({
                                    postId,
                                    currUser,
                                }),
                            ),
                            catchError((error) =>
                                of(
                                    fromActionsApi.likeToggleError({
                                        postId,
                                        currUser,
                                        error,
                                    }),
                                ),
                            ),
                        ),
                    ),
                ),
            ),
        ),
    );

    toggleFollow$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.followToggle),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    concatMap((action) =>
                        of(action).pipe(
                            withLatestFrom(
                                this.store.select(
                                    selectPostById(action.postId),
                                ),
                            ),
                        ),
                    ),
                    concatMap(([{ postId }, post]) =>
                        iif(
                            () => !!post?.followedByMe,
                            this.postService.addPostFollow(postId),
                            this.postService.removePostFollow(postId),
                        ).pipe(
                            map((_) =>
                                fromActionsApi.followToggleSuccess({ postId }),
                            ),
                            catchError((error) =>
                                of(
                                    fromActionsApi.followToggleError({
                                        postId,
                                        error,
                                    }),
                                ),
                            ),
                        ),
                    ),
                ),
            ),
        ),
    );

    participateEvent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.participateEvent),
            groupBy((action) => action.post.id),
            mergeMap((groupById) =>
                groupById.pipe(
                    concatMap(({ post, typeId, notes }) =>
                        this.eventPostService
                            .participate(post.id, typeId, notes)
                            .pipe(
                                map((_) =>
                                    fromActionsApi.participateEventSuccess({
                                        postId: post.id,
                                        typeId,
                                        notes,
                                    }),
                                ),
                                catchError((error) =>
                                    of(
                                        fromActionsApi.participateEventError({
                                            postId: post.id,
                                            previousParticipateType:
                                                post.participateType,
                                            previousParticipantsList:
                                                post.participantsYesList,
                                            error,
                                        }),
                                    ),
                                ),
                            ),
                    ),
                ),
            ),
        ),
    );

    togglePin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.pinToggle),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    concatMap((action) =>
                        of(action).pipe(
                            withLatestFrom(
                                this.store.select(
                                    selectPostById(action.postId),
                                ),
                            ),
                        ),
                    ),
                    concatMap(([{ postId }, post]) =>
                        iif(
                            () => !post?.pinned,
                            this.postService.setupPinPost(postId),
                            this.postService.removePinPost(postId),
                        ).pipe(
                            map((_) =>
                                fromActionsApi.pinToggleSuccess({ postId }),
                            ),
                            catchError((error) =>
                                of(
                                    fromActionsApi.pinToggleError({
                                        postId,
                                        error,
                                    }),
                                ),
                            ),
                        ),
                    ),
                ),
            ),
        ),
    );

    refetchPostsAfterPin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActionsApi.pinToggleSuccess),
            withLatestFrom(this.store.select(AppSelectors.selectRouteState)),
            filter(([_, route]) => route?.appBaseRoute === 'dashboard'),
            map(() =>
                fromActions.fetchPostsWithCurrentFilters({
                    fetchType: 'dashboard',
                }),
            ),
        ),
    );

    changeVisibility$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.visibilityChange),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    concatMap((action) =>
                        iif(
                            () => action.visibility === VisibilityType.PRIVATE,
                            throwError(
                                'The API to change the visibility to Private is not implemented yet',
                            ),
                            of(action),
                        ),
                    ),
                    concatMap(({ postId, visibility }) =>
                        this.postService.publicPost(postId).pipe(
                            map(({ nextOccToken }) =>
                                fromActionsApi.visibilityChangeSuccess({
                                    postId,
                                    visibility,
                                    nextOccToken,
                                }),
                            ),
                            catchError((error) =>
                                of(
                                    fromActionsApi.visibilityChangeError({
                                        postId,
                                        visibility,
                                        error,
                                    }),
                                ),
                            ),
                        ),
                    ),
                ),
            ),
        ),
    );

    showTipOnVisibilityChangeSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActionsApi.visibilityChangeSuccess),
                tap(() => {
                    this.tipService.success(
                        'DETAIL.VISIBILITY.LABEL_CHANGED_WITH_SUCCESS',
                    );
                }),
            ),
        { dispatch: false },
    );

    translate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.translatePost),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    exhaustMap(({ postId, language }) =>
                        this.translationService
                            .getPostTranslation(postId, language)
                            .pipe(
                                map((updatedPost) =>
                                    fromActionsApi.translatePostSuccess({
                                        postId,
                                        updatedPost,
                                    }),
                                ),
                                catchError((error) =>
                                    of(
                                        fromActionsApi.translatePostError({
                                            postId,
                                            error,
                                        }),
                                    ),
                                ),
                            ),
                    ),
                ),
            ),
        ),
    );

    translateError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActionsApi.translatePostError),
                tap(({ error }) => {
                    if (error.status === 404) {
                        this.tipService.warn(
                            'NOTIFICATION_MESSAGE.LABEL_POST_NOT_FOUND',
                            'forceReload',
                        );
                    } else {
                        this.tipService.error(
                            'NOTIFICATION_MESSAGE.LABEL_TRANSLATION_FAILED',
                        );
                    }
                }),
            ),
        { dispatch: false },
    );

    untranslate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.untranslatePost),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    exhaustMap(({ postId }) =>
                        this.translationService.getOriginalPost(postId).pipe(
                            map((updatedPost) =>
                                fromActionsApi.untranslatePostSuccess({
                                    postId,
                                    updatedPost,
                                }),
                            ),
                            catchError((error) =>
                                of(
                                    fromActionsApi.untranslatePostError({
                                        postId,
                                        error,
                                    }),
                                ),
                            ),
                        ),
                    ),
                ),
            ),
        ),
    );

    addWatchers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.addWatchers),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    concatMap(({ postId, watchers }) =>
                        this.postService.addWatchers(watchers, postId).pipe(
                            map((_) =>
                                fromActionsApi.addWatchersSuccess({
                                    postId,
                                    watchers,
                                }),
                            ),
                            catchError((error) =>
                                of(
                                    fromActionsApi.addWatchersError({
                                        postId,
                                        watchers,
                                        error,
                                    }),
                                ),
                            ),
                        ),
                    ),
                ),
            ),
        ),
    );

    removeWatchers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.removeWatchers),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    concatMap(({ postId, watchers }) =>
                        this.postService.removeWatchers(watchers, postId).pipe(
                            map((_) =>
                                fromActionsApi.removeWatchersSuccess({
                                    postId,
                                    watchers,
                                }),
                            ),
                            catchError((error) =>
                                of(
                                    fromActionsApi.removeWatchersError({
                                        postId,
                                        watchers,
                                        error,
                                    }),
                                ),
                            ),
                        ),
                    ),
                ),
            ),
        ),
    );

    reload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.reload),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    exhaustMap(({ postId, reloadComments, parentCommentId }) =>
                        this.postService.getPostDetail(postId, true).pipe(
                            concatMap((post) =>
                                of(post).pipe(
                                    withLatestFrom(
                                        this.store.select(
                                            selectPostById(post.id),
                                        ),
                                    ),
                                ),
                            ),
                            map(([post, currPost]) =>
                                currPost?.isTranslated
                                    ? this.translationService.getTranslatedPostFromCache(
                                          post,
                                      )
                                    : post,
                            ),
                            map((post) =>
                                fromActionsApi.reloadSuccess({
                                    postId,
                                    updatedPost: post,
                                    reloadComments,
                                    parentCommentId,
                                }),
                            ),
                            catchError((error) =>
                                of(
                                    fromActionsApi.reloadError({
                                        postId,
                                        error,
                                    }),
                                ),
                            ),
                        ),
                    ),
                ),
            ),
        ),
    );

    fetchCommentLikers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.fetchCommentLikers),
            groupBy((action) => action.commentId),
            mergeMap((groupById) =>
                groupById.pipe(
                    exhaustMap(({ postId, commentId, pageToken }) =>
                        this.postService
                            .getPostCommentUsers(commentId, pageToken)
                            .pipe(
                                map((likersList) =>
                                    fromActionsApi.fetchCommentLikersSuccess({
                                        postId,
                                        commentId,
                                        likersList,
                                    }),
                                ),
                                catchError((error) =>
                                    of(
                                        fromActionsApi.fetchCommentLikersError({
                                            postId,
                                            commentId,
                                            error,
                                        }),
                                    ),
                                ),
                            ),
                    ),
                ),
            ),
        ),
    );

    fetchVisibleCommentsCount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                fromActions.fetchVisibleCommentsCountDashboard,
                fromActions.fetchVisibleCommentsCountDetail,
            ),
            mergeMap((action) =>
                of(action).pipe(
                    withLatestFrom(
                        this.store.select(selectComments(action.postId)),
                    ),
                    filter(
                        ([action, comments]) =>
                            comments?.list != null &&
                            action.visibleCommentsCount >
                                comments.list.length &&
                            getNextPageToken(comments.nextPageToken) != null,
                    ),
                    map(([action, comments]) =>
                        fromActions.fetchComments({
                            postId: action.postId,
                            pageToken:
                                getNextPageToken(comments!.nextPageToken) ??
                                undefined,
                            pageSize:
                                action.visibleCommentsCount -
                                comments!.list.length,
                        }),
                    ),
                ),
            ),
        ),
    );

    fetchComments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.fetchComments),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    mergeMap((action) =>
                        of(action).pipe(
                            withLatestFrom(
                                this.store.select(
                                    PostSelectors.selectPostById(action.postId),
                                ),
                            ),
                        ),
                    ),
                    mergeMap(([action, post]) =>
                        post && post.capabilities
                            ? of({ action, capabilities: post.capabilities })
                            : this.postService
                                  .getPostCapabilities(action.postId)
                                  .pipe(
                                      map((capabilities) => ({
                                          action,
                                          capabilities,
                                      })),
                                  ),
                    ),
                    filterMap(({ action, capabilities }) =>
                        capabilities.canViewComment ? action : undefined,
                    ),
                    exhaustMap(({ pageToken, postId, pageSize }) =>
                        this.postService
                            .getPostComments(postId, pageSize, pageToken)
                            .pipe(
                                map((commentsList) => ({
                                    ...commentsList,
                                    list: commentsList.list.map((c) =>
                                        this.translationService.getTranslatedCommentFromCache(
                                            c,
                                        ),
                                    ),
                                })),
                                map((commentsList) =>
                                    fromActionsApi.fetchCommentsSuccess({
                                        postId,
                                        commentsList,
                                        pageToken,
                                    }),
                                ),
                                catchError((error) =>
                                    of(
                                        fromActionsApi.fetchCommentsError({
                                            postId,
                                            error,
                                        }),
                                    ),
                                ),
                            ),
                    ),
                ),
            ),
        ),
    );

    willFetchCommentsAfterReloadSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActionsApi.reloadSuccess),
            filter((action) => action.reloadComments),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    map(({ postId, parentCommentId }) =>
                        parentCommentId
                            ? fromActions.fetchCommentsUntilId({
                                  postId: postId,
                                  parentCommentId,
                              })
                            : fromActions.fetchComments({
                                  postId,
                              }),
                    ),
                ),
            ),
        ),
    );

    requestCommentsUntilId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.requestCommentsUntilId),
            map(({ post, parentCommentId }) => {
                if (
                    post.comments.list.find((c) => c.id === parentCommentId) ==
                    null
                ) {
                    return fromActions.fetchCommentsUntilId({
                        postId: post.id,
                        parentCommentId,
                    });
                } else {
                    const navigatedIndex = Math.max(
                        post.comments.list.findIndex(
                            (c) => c.id === parentCommentId,
                        ) + 1,
                        2,
                    );
                    return fromActions.fetchVisibleCommentsCountDetail({
                        postId: post.id,
                        visibleCommentsCount: navigatedIndex,
                    });
                }
            }),
        ),
    );

    findParentCommentId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.fetchCommentsUntilId),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    switchMap(({ postId, pageToken, parentCommentId }) =>
                        this.postService
                            .getPostComments(postId, 20, pageToken)
                            .pipe(
                                expand((res) =>
                                    res.nextPageToken.tag ===
                                        'regularLoading' &&
                                    !res.list.find(
                                        (c) => c.id === parentCommentId,
                                    )
                                        ? this.postService.getPostComments(
                                              postId,
                                              20,
                                              res.nextPageToken.nextPageToken,
                                          )
                                        : EMPTY,
                                ),
                                toArray(),
                                map((responses) => ({
                                    ...responses[responses.length - 1],
                                    list: flatten(responses.map((r) => r.list)),
                                })),
                                map((commentsList) =>
                                    fromActionsApi.fetchCommentsSuccess({
                                        postId,
                                        commentsList,
                                        pageToken,
                                    }),
                                ),
                            ),
                    ),
                ),
            ),
        ),
    );

    fetchAttachments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.fetchAttachments),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    mergeMap(
                        ({
                            postId,
                            attachmentType,
                            pageSize,
                            calculateTotalItemsCount,
                            entityTypes,
                        }) =>
                            this.attachmentService
                                .loadPostAttachments(postId, {
                                    attachmentCategoryType: attachmentType,
                                    pageSize: isDefined(pageSize)
                                        ? pageSize
                                        : undefined,
                                    calculateTotalItemsCount,
                                    entityTypes,
                                    order: getPostAttachmentsOrder(entityTypes),
                                })
                                .pipe(
                                    map((attachmentsList) =>
                                        fromActionsApi.fetchAttachmentsSuccess({
                                            postId,
                                            attachmentsList,
                                            attachmentType,
                                            entityTypes,
                                        }),
                                    ),
                                    catchError((error) =>
                                        of(
                                            fromActionsApi.fetchAttachmentsError(
                                                {
                                                    postId,
                                                    attachmentType,
                                                    error,
                                                    entityTypes,
                                                },
                                            ),
                                        ),
                                    ),
                                ),
                    ),
                ),
            ),
        ),
    );

    fetchAttachmentsPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.fetchAttachmentsPage),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    exhaustMap(
                        ({
                            postId,
                            nextPageToken,
                            attachmentType,
                            pageSize,
                            entityTypes,
                        }) =>
                            this.attachmentService
                                .loadPostAttachments(postId, {
                                    attachmentCategoryType: attachmentType,
                                    nextPageToken,
                                    pageSize: isDefined(pageSize)
                                        ? pageSize
                                        : undefined,
                                    entityTypes,
                                    order: getPostAttachmentsOrder(entityTypes),
                                })
                                .pipe(
                                    map((attachmentsList) =>
                                        fromActionsApi.fetchAttachmentsPageSuccess(
                                            {
                                                postId,
                                                attachmentType,
                                                attachmentsList,
                                                entityTypes,
                                            },
                                        ),
                                    ),
                                    catchError((error) =>
                                        of(
                                            fromActionsApi.fetchAttachmentsPageError(
                                                {
                                                    postId,
                                                    attachmentType,
                                                    error,
                                                    entityTypes,
                                                },
                                            ),
                                        ),
                                    ),
                                ),
                    ),
                ),
            ),
        ),
    );

    attachmentsAdd$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.addAttachments),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    concatMap(({ postId, attachments }) =>
                        this.postService
                            .editPostAttachments(
                                {
                                    addAttachments: attachments,
                                    updateAttachments: [],
                                    removeAttachmentIds: [],
                                },
                                postId,
                            )
                            .pipe(
                                map(({ addAttachments, nextOccToken }) =>
                                    fromActionsApi.addAttachmentsSuccess({
                                        postId,
                                        attachments: addAttachments,
                                        nextOccToken,
                                        showTip: true,
                                    }),
                                ),
                                catchError((error) =>
                                    of(
                                        fromActionsApi.addAttachmentsError({
                                            postId,
                                            attachments,
                                            error,
                                            showTip: true,
                                        }),
                                    ),
                                ),
                            ),
                    ),
                ),
            ),
        ),
    );

    attachmentsEdit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.editAttachments),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    concatMap(({ postId, attachments }) =>
                        this.postService
                            .editPostAttachments(
                                {
                                    addAttachments: [],
                                    updateAttachments: attachments,
                                    removeAttachmentIds: [],
                                },
                                postId,
                            )
                            .pipe(
                                map((attachmentsEditRes) =>
                                    fromActionsApi.attachmentsEditSuccess({
                                        postId,
                                        attachmentsEdit: attachmentsEditRes,
                                    }),
                                ),
                                catchError((error) =>
                                    of(
                                        fromActionsApi.attachmentsEditError({
                                            postId,
                                            error,
                                        }),
                                    ),
                                ),
                            ),
                    ),
                ),
            ),
        ),
    );

    reloadAttachmentsAfterEdit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActionsApi.attachmentsEditSuccess),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    map(({ postId }) =>
                        fromActions.fetchAttachments({
                            postId,
                            calculateTotalItemsCount: true,
                            entityTypes: ['post'],
                        }),
                    ),
                ),
            ),
        ),
    );

    attachmentsDelete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.deleteAttachments),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    exhaustMap(({ postId, attachmentsIds }) =>
                        this.attachmentService
                            .deleteBatchAttachments(attachmentsIds)
                            .pipe(
                                map((deletedIds) =>
                                    fromActionsApi.deleteAttachmentsSuccess({
                                        postId,
                                        deletedIds,
                                    }),
                                ),
                                catchError((error) =>
                                    of(
                                        fromActionsApi.deleteAttachmentsError({
                                            postId,
                                            error,
                                        }),
                                    ),
                                ),
                            ),
                    ),
                ),
            ),
        ),
    );

    addAttachmentsSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActionsApi.addAttachmentsSuccess),
                filter(({ showTip }) => !!showTip),
                tap(({ attachments }) => {
                    const message =
                        attachments.length > 1
                            ? 'DASHBOARD.ATTACHMENTS.ATTACHMENTS_ADD_SUCCESS'
                            : 'DASHBOARD.ATTACHMENTS.ATTACHMENT_ADD_SUCCESS';
                    this.tipService.success(message, {
                        counter: attachments.length,
                    });
                }),
            ),
        { dispatch: false },
    );

    addAttachmentsError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActionsApi.addAttachmentsError),
                filter(({ showTip }) => !!showTip),
                tap(({ error }) => {
                    this.errorService.handle(error);
                }),
            ),
        { dispatch: false },
    );

    fetchViewers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.fetchViewers),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    mergeMap((action) =>
                        this.communitiesStateService
                            .getCommunity(action.communityId)
                            .pipe(
                                map((community) => ({
                                    ...action,
                                    community,
                                })),
                            ),
                    ),
                    switchMap(({ postId, pageToken, name, community }) =>
                        (community.capabilities?.viewPostVisualizations
                            ? this.postService.getViewerList(
                                  postId,
                                  pageToken,
                                  name,
                              )
                            : of(paginatedListFromArray<IUserAction>([]))
                        ).pipe(
                            map((viewersList) =>
                                fromActionsApi.fetchViewersSuccess({
                                    postId,
                                    viewersList,
                                    pageToken,
                                    name,
                                }),
                            ),
                            catchError((error) =>
                                of(
                                    fromActionsApi.fetchViewersError({
                                        postId,
                                        error,
                                    }),
                                ),
                            ),
                        ),
                    ),
                ),
            ),
        ),
    );

    fetchLikers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.fetchLikers),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    switchMap(({ postId, pageToken, name }) =>
                        this.postService
                            .getLikeList(postId, pageToken, name)
                            .pipe(
                                map((likersList) =>
                                    fromActionsApi.fetchLikersSuccess({
                                        postId,
                                        likersList,
                                        pageToken,
                                        name,
                                    }),
                                ),
                                catchError((error) =>
                                    of(
                                        fromActionsApi.fetchLikersError({
                                            postId,
                                            error,
                                        }),
                                    ),
                                ),
                            ),
                    ),
                ),
            ),
        ),
    );

    fetchParticipants$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.fetchParticipants),
            groupBy((action) => action.typeId),
            mergeMap((groupByTypeId) =>
                groupByTypeId.pipe(
                    switchMap(({ postId, typeId, pageToken, name }) =>
                        this.eventPostService
                            .getParticipantsList(
                                postId,
                                typeId,
                                pageToken,
                                name,
                            )
                            .pipe(
                                map((participantsList) =>
                                    fromActionsApi.fetchParticipantsSuccess({
                                        postId,
                                        participantsList,
                                        typeId,
                                        pageToken,
                                        name,
                                    }),
                                ),
                                catchError((error) =>
                                    of(
                                        fromActionsApi.fetchParticipantsError({
                                            postId,
                                            typeId,
                                            error,
                                        }),
                                    ),
                                ),
                            ),
                    ),
                ),
            ),
        ),
    );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.deletePost),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    exhaustMap(({ postId, navigateToCommunity, communityId }) =>
                        this.postService.deletePost(postId).pipe(
                            map((_) =>
                                fromActionsApi.deletePostSuccess({
                                    postId,
                                    communityId,
                                    navigateToCommunity,
                                }),
                            ),
                            catchError((error) =>
                                of(
                                    fromActionsApi.deletePostError({
                                        postId,
                                        error,
                                    }),
                                ),
                            ),
                        ),
                    ),
                ),
            ),
        ),
    );

    massiveDelete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.massiveDeletePosts),
            mergeMap(({ postIds }) =>
                this.postService.deletePostBatch(postIds).pipe(
                    map((resPostIds) =>
                        fromActionsApi.massiveDeletePostsSuccess({
                            postIds: resPostIds,
                        }),
                    ),
                    catchError((error) =>
                        of(
                            fromActionsApi.massiveDeletePostsError({
                                postIds,
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    navigateAfterDeleteSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActionsApi.deletePostSuccess),
                filter(
                    ({ communityId, navigateToCommunity }) =>
                        navigateToCommunity && isDefined(communityId),
                ),
                tap(({ communityId }) => {
                    this.router.navigate([dashboardCommunity, communityId], {
                        replaceUrl: true,
                    });
                }),
            ),
        { dispatch: false },
    );

    showConfirmToastAfterDelete$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActionsApi.deletePostSuccess),
                tap(() => {
                    this.tipService.success('POST.LABEL_DELETED_WITH_SUCCESS');
                }),
            ),
        { dispatch: false },
    );

    showConfirmToastAfterMassiveDelete$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActionsApi.massiveDeletePostsSuccess),
                tap(({ postIds }) => {
                    this.tipService.success(
                        'POST.LABEL_DELETED_WITH_SUCCESS_MULTI',
                        { counter: postIds.length },
                    );
                }),
            ),
        { dispatch: false },
    );

    fetchLinkedPosts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.fetchLinkedPosts),
            switchMap(({ postId, communityId, metadata, filters }) =>
                this.postListService
                    .getLinkedPosts(postId, communityId, metadata, filters)
                    .pipe(
                        map((posts) =>
                            fromActionsApi.fetchLinkedPostsSuccess({
                                postId,
                                fetchPageToken: filters.pageToken,
                                items: { communityId, posts },
                            }),
                        ),
                        catchError((error) =>
                            of(
                                fromActionsApi.fetchLinkedPostsError({
                                    postId,
                                    communityId,
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    commentFieldHasChanged$ = createEffect(() =>
        merge(
            this.actions$.pipe(
                ofType(fromActionsApi.confirmAcknowledgeTaskSuccess),
            ),
            this.actions$.pipe(
                ofType(fromActionsApi.editSuccess),
                map(({ community, editedPost }) => ({
                    fieldDefinition: community.metadata?.fieldMetadatas ?? [],
                    postId: editedPost.id,
                })),
            ),
            this.actions$.pipe(
                ofType(fromActionsApi.workflowTransitionSuccess),
                map((action) => ({
                    postId: action.post.id,
                    fieldDefinition:
                        action.transition.screen?.fieldMetadatas ?? [],
                })),
            ),
        ).pipe(
            filter(
                (action) =>
                    action.fieldDefinition != null &&
                    action.fieldDefinition.length > 0 &&
                    action.fieldDefinition.some(
                        (f) => f.metadata?.generate_post_comment,
                    ),
            ),
            map(({ postId }) =>
                fromActions.fetchComments({
                    postId,
                }),
            ),
        ),
    );

    postDataHasChanged$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                fromActionsApi.workflowTransitionSuccess,
                fromActionsApi.editWorkflowScreenSuccess,
            ),
            filter(({ workflowChange }) => workflowChange.postDataHasChanged),
            switchMap(({ post }) =>
                of(
                    fromActions.fetchPosts({
                        fetchType: 'detail',
                        postId: post.id,
                    }),
                    fromActions.fetchComments({
                        postId: post.id,
                    }),
                    fromActions.fetchAttachments({
                        postId: post.id,
                        entityTypes: ['post'],
                        attachmentType: AttachmentCategoryType.MULTIMEDIA,
                        calculateTotalItemsCount: true,
                    }),
                    fromActions.fetchAttachments({
                        postId: post.id,
                        entityTypes: ['post'],
                        attachmentType: AttachmentCategoryType.DOCUMENT,
                        calculateTotalItemsCount: true,
                    }),
                    TaskActions.fetchTasks({
                        postId: post.id,
                        filters: emptyTaskFilters(),
                    }),
                ),
            ),
        ),
    );

    fetchLinksAndRelationsCount$ = createEffect(() =>
        groupByPostId(
            this.actions$.pipe(ofType(fromActions.fetchLinksAndRelationsCount)),
            switchMap(({ postId }) =>
                this.postService.communityRelationsPostsCounters(postId).pipe(
                    map((linksAndRelationsCount) =>
                        fromActionsApi.fetchLinksAndRelationsCountSuccess({
                            postId,
                            linksAndRelationsCount: linksAndRelationsCount,
                        }),
                    ),
                    catchError((error) =>
                        of(
                            fromActionsApi.fetchLinksAndRelationsCountError({
                                postId,
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    addLinkedPosts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.addLinkedPosts),
            mergeMap(({ posts, field, linkedPost }) =>
                this.customPostService
                    .editPostPicker(posts, linkedPost.id, field.id)
                    .pipe(
                        map((nextPosts: IEditPostLinksResponse) =>
                            fromActionsApi.addLinkedPostsSuccess({
                                posts,
                                field,
                                linkedPost,
                                linksSuccess: nextPosts.linksSuccess,
                                linksErrorConcurrency:
                                    nextPosts.linksErrorConcurrency,
                                linksErrorValidation:
                                    nextPosts.linksErrorValidation,
                            }),
                        ),
                        catchError((error) =>
                            of(
                                fromActionsApi.addLinkedPostsError({
                                    posts,
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    removePostsFromRelations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.removePostsFromRelations),
            mergeMap(({ post, fieldsDefinition, removePostIds }) =>
                this.customPostService
                    .addOrRemovePostFromRelations(
                        post.id,
                        fieldsDefinition.length
                            ? mapArrayById(fieldsDefinition) ?? null
                            : null,
                        removePostIds,
                    )
                    .pipe(
                        map((nextPost) =>
                            fromActionsApi.removePostsFromRelationsSuccess({
                                post: {
                                    ...post,
                                    customFields: nextPost.customFields,
                                },
                                nextOccToken: nextPost.occToken!,
                                fieldsDefinition,
                            }),
                        ),
                        catchError((error) => {
                            return of(
                                fromActionsApi.removePostsFromRelationsError({
                                    post,
                                    error: hasRequiredFieldValidationError(
                                        error,
                                    )
                                        ? new CustomError(
                                              'POST.POST_LINK_AND_RELATION.LABEL_REQUIRED_FIELD_CANT_REMOVE',
                                              true,
                                          )
                                        : error,
                                }),
                            );
                        }),
                    ),
            ),
        ),
    );

    fetchWorkflowHistory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.fetchWorkflowHistory),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    switchMap(({ postId, filter, communityId }) =>
                        this.postService
                            .getPostHistory(
                                postId,
                                {
                                    ...filter,
                                    orderDesc: true,
                                    retrieveWorkflowEvents: true,
                                    retrievePostEvents: false,
                                },
                                communityId,
                            )
                            .pipe(
                                map((workflowHistory) =>
                                    fromActionsApi.fetchWorkflowHistorySuccess({
                                        postId,
                                        workflowHistory,
                                        prevPageToken: filter?.pageToken,
                                    }),
                                ),
                                catchError((error) =>
                                    of(
                                        fromActionsApi.fetchWorkflowHistoryError(
                                            {
                                                postId,
                                                error,
                                            },
                                        ),
                                    ),
                                ),
                            ),
                    ),
                ),
            ),
        ),
    );

    postCreate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.create),
            concatMap(
                ({ community, postCreate, redirectTo, successMessage }) => {
                    const api$: Observable<GenericPost> = this.getCreatePostApi(
                        community,
                        postCreate,
                    );

                    const visibleCommunities$ =
                        this.communitiesStateService.state
                            .communitiesVisibleInDashboard$;

                    return api$.pipe(
                        withLatestFrom(visibleCommunities$),
                        map(([createdPost, visibleCommunities]) =>
                            fromActionsApi.createSuccess({
                                createdPost,
                                redirectTo,
                                successMessage,
                                showInVisibleCommunities:
                                    visibleCommunities.some(
                                        (c) => c.id === createdPost.communityId,
                                    ),
                            }),
                        ),
                        catchError((error) =>
                            of(fromActionsApi.createError({ error })),
                        ),
                    );
                },
            ),
        ),
    );

    postEdit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.edit),
            concatMap(({ community, postEdit, isPublishing, redirectTo }) => {
                const api$: Observable<GenericPost> = this.getEditPostApi(
                    community,
                    postEdit,
                );

                return api$.pipe(
                    tap((editedPost) =>
                        this.translationService.removePostFromCache(
                            editedPost.id,
                        ),
                    ),
                    map((editedPost) =>
                        fromActionsApi.editSuccess({
                            editedPost,
                            community,
                            redirectTo,
                            isPublishing,
                        }),
                    ),
                    catchError((error) =>
                        of(fromActionsApi.editError({ error })),
                    ),
                );
            }),
        ),
    );

    postCopy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.copy),
            concatMap(
                ({
                    originalPostId,
                    community,
                    postCopy,
                    redirectTo,
                    successMessage,
                }) => {
                    const api$: Observable<GenericPost> = this.getCopyPostApi(
                        originalPostId,
                        postCopy,
                        community.metadata!,
                    );

                    const visibleCommunities$ =
                        this.communitiesStateService.state
                            .communitiesVisibleInDashboard$;

                    return api$.pipe(
                        withLatestFrom(visibleCommunities$),
                        map(([copiedPost, visibleCommunities]) =>
                            fromActionsApi.copySuccess({
                                copiedPost,
                                community,
                                redirectTo,
                                successMessage,
                                showInVisibleCommunities:
                                    visibleCommunities.some(
                                        (c) => c.id === copiedPost.communityId,
                                    ),
                            }),
                        ),
                        catchError((error) =>
                            of(fromActionsApi.copyError({ error })),
                        ),
                    );
                },
            ),
        ),
    );

    previousRoute$: Observable<AppRouterState | undefined> = concat(
        this.store.select(AppSelectors.selectRouteState).pipe(first()),
        this.store.select(AppSelectors.selectRouteState).pipe(
            pairwise(),
            map(([prev, _]) => prev),
        ),
    );

    showTipOnSavePostsSuccess$ = createEffect(
        () =>
            merge(
                this.actions$.pipe(
                    ofType(fromActionsApi.createSuccess),
                    map(({ createdPost, successMessage }) => ({
                        post: createdPost,
                        successMessage:
                            successMessage ?? 'POST.TIP_SUCCESSFULLY_CREATED',
                    })),
                ),
                this.actions$.pipe(
                    ofType(fromActionsApi.editSuccess),
                    filter(({ isPublishing }) => isPublishing),
                    map(({ editedPost }) => ({
                        post: editedPost,
                        successMessage: 'POST.TIP_SUCCESSFULLY_CREATED',
                    })),
                ),
                this.actions$.pipe(
                    ofType(fromActionsApi.copySuccess),
                    map(({ copiedPost, successMessage }) => ({
                        post: copiedPost,
                        successMessage:
                            successMessage ?? 'POST.TIP_SUCCESSFULLY_CREATED',
                    })),
                ),
            ).pipe(
                filter(({ post }) => !isFeedbackPostInProcessingStatus(post)),
                tap(({ successMessage }) =>
                    this.tipService.success(successMessage),
                ),
            ),
        { dispatch: false },
    );

    showProTipOnPublishMissionSuccess$ = createEffect(
        () =>
            merge(
                this.actions$.pipe(
                    ofType(fromActionsApi.createSuccess),
                    map(({ createdPost }) => ({
                        post: createdPost,
                    })),
                ),
                this.actions$.pipe(
                    ofType(fromActionsApi.editSuccess),
                    filter(({ isPublishing }) => isPublishing),
                    map(({ editedPost }) => ({
                        post: editedPost,
                    })),
                ),
                this.actions$.pipe(
                    ofType(fromActionsApi.copySuccess),
                    map(({ copiedPost }) => ({
                        post: copiedPost,
                    })),
                ),
            ).pipe(
                filter(({ post }) => isFeedbackPostInProcessingStatus(post)),
                tap(() =>
                    this.tipService
                        .openProTip({
                            closeBehavior: 'manual',
                            image: 'gardening',
                            message: 'POST.TIP_SUCCESSFULLY_CREATED_MISSION',
                        })
                        .subscribe(),
                ),
            ),
        { dispatch: false },
    );

    redirectAfterCreateOrEditOrCopy$ = createEffect(
        () =>
            merge(
                this.actions$.pipe(
                    ofType(fromActionsApi.createSuccess),
                    filter((action) => !!action.redirectTo),
                    map(({ createdPost, redirectTo }) => ({
                        post: createdPost,
                        redirectTo,
                    })),
                ),
                this.actions$.pipe(
                    ofType(fromActionsApi.editSuccess),
                    filter((action) => !!action.redirectTo),
                    map(({ editedPost, redirectTo }) => ({
                        post: editedPost,
                        redirectTo,
                    })),
                ),
                this.actions$.pipe(
                    ofType(fromActionsApi.copySuccess),
                    filter((action) => !!action.redirectTo),
                    map(({ copiedPost, redirectTo }) => ({
                        post: copiedPost,
                        redirectTo,
                    })),
                ),
            ).pipe(
                withLatestFrom(this.previousRoute$),
                tap(([items, prevRoute]) => {
                    if (
                        prevRoute?.appBaseRoute === 'post-detail' &&
                        parseInt(prevRoute.params.id as string) ===
                            items.post.id
                    ) {
                        this.historyRoutingService.back();
                    } else {
                        switch (items.redirectTo) {
                            case 'post-detail':
                                this.router.navigate(
                                    ['/', 'post', items.post.id],
                                    {
                                        replaceUrl: true,
                                    },
                                );
                                break;
                            case 'manage':
                                this.router.navigate(['/manage/drafts'], {
                                    replaceUrl: true,
                                });
                                break;
                            default:
                                this.router.navigate(['/'], {
                                    replaceUrl: true,
                                });
                                break;
                        }
                    }
                }),
            ),
        { dispatch: false },
    );

    workflowTransition$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.workflowTransition),
            groupBy((action) => action.post.id),
            mergeMap((groupById) =>
                groupById.pipe(
                    exhaustMap(({ post, transition, screen }) =>
                        this.workflowService
                            .setWorkflowState(post, transition, screen ?? null)
                            .pipe(
                                map((workflowChange) =>
                                    PostActionsApi.workflowTransitionSuccess({
                                        post,
                                        transition,
                                        screen,
                                        workflowChange,
                                    }),
                                ),
                                catchError((error) =>
                                    of(
                                        PostActionsApi.workflowTransitionError({
                                            postId: post.id,
                                            error,
                                        }),
                                    ),
                                ),
                            ),
                    ),
                ),
            ),
        ),
    );

    editWorkflowScreen$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.editWorkflowScreen),
            groupBy((action) => action.post.id),
            mergeMap((groupById) =>
                groupById.pipe(
                    exhaustMap(({ post, screen }) =>
                        this.workflowService.editScreen(post, screen).pipe(
                            map((workflowChange) =>
                                PostActionsApi.editWorkflowScreenSuccess({
                                    post,
                                    workflowChange,
                                }),
                            ),
                            catchError((error) =>
                                of(
                                    PostActionsApi.editWorkflowScreenError({
                                        postId: post.id,
                                        error,
                                    }),
                                ),
                            ),
                        ),
                    ),
                ),
            ),
        ),
    );

    refreshWorkflow$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                fromActionsApi.workflowTransitionSuccess,
                fromActionsApi.editWorkflowScreenSuccess,
            ),
            groupBy((action) => action.post.id),
            mergeMap((groupById) =>
                groupById.pipe(
                    map(({ post }) =>
                        fromActions.fetchWorkflowHistory({
                            postId: post.id,
                            communityId: post.communityId,
                        }),
                    ),
                ),
            ),
        ),
    );

    fetchVisibilityCounters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.fetchVisibilityCounters),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    exhaustMap(({ postId, communityId, surveyType }) =>
                        this.postService
                            .getVisibilityCounters(postId, surveyType)
                            .pipe(
                                map((counters) =>
                                    PostActionsApi.fetchVisibilityCountersSuccess(
                                        {
                                            postId,
                                            counters,
                                            communityId,
                                        },
                                    ),
                                ),
                                catchError((error) =>
                                    of(
                                        PostActionsApi.fetchVisibilityCountersError(
                                            {
                                                postId,
                                                error,
                                            },
                                        ),
                                    ),
                                ),
                            ),
                    ),
                ),
            ),
        ),
    );

    fetchMentionsListOnCounterSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActionsApi.fetchVisibilityCountersSuccess),
            map(({ postId }) =>
                fromActions.fetchMentionsList({
                    postId,
                    pageToken: undefined,
                }),
            ),
        ),
    );

    fetchCommunityViewersListOnCounterSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActionsApi.fetchVisibilityCountersSuccess),
            map(({ postId, counters, communityId }) =>
                counters.viewPrivatePostCapabilityCount
                    ? fromActions.fetchCommunityViewersList({
                          communityId,
                          postId,
                      })
                    : fromActionsApi.fetchCommunityViewersListSuccess({
                          postId,
                          members: emptyPaginatedList(),
                      }),
            ),
        ),
    );

    fetchExtendedVisibilityOnCounterSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActionsApi.fetchVisibilityCountersSuccess),
            filter(({ counters }) => !!counters.extendedVisibilityCount),
            map(({ postId }) =>
                fromActions.fetchOthersVisibilityList({
                    postId,
                }),
            ),
        ),
    );

    fetchTaskAssigneesListOnCounterSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActionsApi.fetchVisibilityCountersSuccess),
            filter(({ counters }) => !!counters.taskAssigneesCount),
            map(({ postId }) =>
                fromActions.fetchTasksAssigneesList({
                    postId,
                    pageToken: null,
                    taskType: ITaskType.STANDARD,
                }),
            ),
        ),
    );

    fetchAckTaskAssigneesListOnCounterSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActionsApi.fetchVisibilityCountersSuccess),
            filter(({ counters }) => !!counters.ackTaskAssigneesCount),
            map(({ postId }) =>
                fromActions.fetchTasksAssigneesList({
                    postId,
                    pageToken: null,
                    taskType: ITaskType.ACKNOWLEDGE,
                }),
            ),
        ),
    );

    fetchAnswerTaskAssigneesListOnCounterSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActionsApi.fetchVisibilityCountersSuccess),
            filter(
                ({ counters }) =>
                    !!counters.surveyTaskAssigneesCount ||
                    !!counters.feedbackTaskAssigneesCount,
            ),
            map(({ postId, counters }) =>
                fromActions.fetchTasksAssigneesList({
                    postId,
                    pageToken: null,
                    taskType: counters.surveyTaskAssigneesCount
                        ? ITaskType.SURVEY
                        : ITaskType.FEEDBACK,
                }),
            ),
        ),
    );

    fetchMentionsList = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.fetchMentionsList),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    exhaustMap(({ postId, pageToken }) =>
                        this.postService.getMentions(postId, pageToken).pipe(
                            map((members) =>
                                PostActionsApi.fetchMentionsListSuccess({
                                    postId,
                                    members,
                                }),
                            ),
                            catchError((error) =>
                                of(
                                    PostActionsApi.fetchMentionsListError({
                                        postId,
                                        error,
                                    }),
                                ),
                            ),
                        ),
                    ),
                ),
            ),
        ),
    );

    fetchInvitationsList = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.fetchInvitationsList),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    exhaustMap(({ postId, pageToken }) =>
                        this.postService.getInvitations(postId, pageToken).pipe(
                            map((members) =>
                                PostActionsApi.fetchInvitationsListSuccess({
                                    postId,
                                    members,
                                }),
                            ),
                            catchError((error) =>
                                of(
                                    PostActionsApi.fetchInvitationsListError({
                                        postId,
                                        error,
                                    }),
                                ),
                            ),
                        ),
                    ),
                ),
            ),
        ),
    );

    editInvitationsList = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.editInvitationsList),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    exhaustMap(({ postId, params }) =>
                        this.postService.editInvitations(postId, params).pipe(
                            map((_) =>
                                PostActionsApi.editInvitationsListSuccess({
                                    postId,
                                    params,
                                }),
                            ),
                            catchError((error) =>
                                of(
                                    PostActionsApi.editInvitationsListError({
                                        postId,
                                        error,
                                    }),
                                ),
                            ),
                        ),
                    ),
                ),
            ),
        ),
    );

    editInvitationsListSuccess = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActionsApi.editInvitationsListSuccess),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    map(({ postId }) =>
                        fromActions.fetchParticipants({
                            postId,
                            typeId: EventParticipateType.MAYBE,
                        }),
                    ),
                    map(({ postId }) =>
                        fromActions.fetchParticipants({
                            postId,
                            typeId: EventParticipateType.NO,
                        }),
                    ),
                    map(({ postId }) =>
                        fromActions.fetchParticipants({
                            postId,
                            typeId: EventParticipateType.YES,
                        }),
                    ),
                ),
            ),
        ),
    );

    fetchGroupInvitationsList = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.fetchGroupInvitationsList),
            groupBy((action) => action.groupId),
            mergeMap((groupById) =>
                groupById.pipe(
                    exhaustMap(({ postId, groupId, pageToken }) =>
                        this.postService
                            .getGroupInvitations(postId, groupId, pageToken)
                            .pipe(
                                map((members) =>
                                    PostActionsApi.fetchGroupInvitationsListSuccess(
                                        {
                                            postId,
                                            groupId,
                                            members,
                                        },
                                    ),
                                ),
                                catchError((error) =>
                                    of(
                                        PostActionsApi.fetchGroupInvitationsListError(
                                            {
                                                postId,
                                                groupId,
                                                error,
                                            },
                                        ),
                                    ),
                                ),
                            ),
                    ),
                ),
            ),
        ),
    );

    fetchTaskAssigneesList = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.fetchTasksAssigneesList),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    mergeMap(({ postId, pageToken, taskType }) =>
                        this.postService
                            .getAssigneesTask(postId, pageToken, taskType)
                            .pipe(
                                map((members) =>
                                    PostActionsApi.fetchTasksAssigneesListSuccess(
                                        { members, taskType, postId },
                                    ),
                                ),
                                catchError((error) =>
                                    of(
                                        PostActionsApi.fetchTasksAssigneesListError(
                                            {
                                                postId,
                                                error,
                                            },
                                        ),
                                    ),
                                ),
                            ),
                    ),
                ),
            ),
        ),
    );

    fetchCommunityViewersList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.fetchCommunityViewersList),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    exhaustMap(({ filters, communityId, postId }) => {
                        return this.communitiesService
                            .getCommunityViewersList(communityId, filters)
                            .pipe(
                                map((members) =>
                                    PostActionsApi.fetchCommunityViewersListSuccess(
                                        {
                                            postId,
                                            members,
                                            filters,
                                        },
                                    ),
                                ),
                                catchError((error) =>
                                    of(
                                        PostActionsApi.fetchCommunityViewersListError(
                                            {
                                                postId,
                                                error,
                                            },
                                        ),
                                    ),
                                ),
                            );
                    }),
                ),
            ),
        ),
    );

    fetchOthersVisibilityList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.fetchOthersVisibilityList),
            groupBy((action) => action.postId),
            mergeMap((groupByPostId) =>
                groupByPostId.pipe(
                    exhaustMap(({ postId }) => {
                        return this.postService
                            .getOthersVisibilityList(postId)
                            .pipe(
                                map((members) =>
                                    PostActionsApi.fetchOthersVisibilityListSuccess(
                                        {
                                            postId,
                                            members,
                                        },
                                    ),
                                ),
                                catchError((error) =>
                                    of(
                                        PostActionsApi.fetchOthersVisibilityListError(
                                            {
                                                postId,
                                                error,
                                            },
                                        ),
                                    ),
                                ),
                            );
                    }),
                ),
            ),
        ),
    );

    getStreamingVideo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.getStreamingVideo),
            groupBy((action) => action.postId),
            mergeMap((groupByPostId) =>
                groupByPostId.pipe(
                    switchMap(({ postId }) => {
                        return this.eventPostService
                            .getStreamingVideo(postId)
                            .pipe(
                                map((streamingVideoSrc) =>
                                    PostActionsApi.getStreamingVideoSuccess({
                                        postId,
                                        streamingVideoSrc,
                                    }),
                                ),
                                catchError((error) =>
                                    of(
                                        PostActionsApi.getStreamingVideoError({
                                            postId,
                                            error,
                                        }),
                                    ),
                                ),
                            );
                    }),
                ),
            ),
        ),
    );

    fetchStreamingComments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.fetchStreamingComments),
            groupBy((action) => action.postId),
            mergeMap((groupById) =>
                groupById.pipe(
                    exhaustMap(({ pageToken, syncToken, postId, pageSize }) =>
                        this.eventPostService
                            .getPostStreamingComments(
                                postId,
                                pageSize,
                                pageToken,
                                syncToken,
                            )
                            .pipe(
                                map((commentsList) => ({
                                    ...commentsList,
                                    list: commentsList.list.map((c) =>
                                        this.translationService.getTranslatedCommentFromCache(
                                            c,
                                        ),
                                    ),
                                })),
                                map((commentsList) =>
                                    fromActionsApi.fetchStreamingCommentsSuccess(
                                        {
                                            postId,
                                            commentsList,
                                            pageToken,
                                            syncToken,
                                        },
                                    ),
                                ),
                                catchError((error) =>
                                    of(
                                        fromActionsApi.fetchStreamingCommentsError(
                                            {
                                                postId,
                                                error,
                                            },
                                        ),
                                    ),
                                ),
                            ),
                    ),
                ),
            ),
        ),
    );

    fetchFilePickerAttachments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.fetchFilePickerAttachments),
            concatMap(({ postId, attachmentCategoryType }) =>
                of(
                    fromActions.fetchAttachments({
                        postId,
                        attachmentType: attachmentCategoryType,
                        entityTypes: ['postFilePicker', 'screenFilePicker'],
                        calculateTotalItemsCount: true,
                    }),
                ),
            ),
        ),
    );

    fetchFilePickerAttachmentsOnWorkflowAttachmentChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActionsApi.fetchWorkflowHistorySuccess),
            concatMap(({ postId }) =>
                from([
                    fromActions.fetchAttachments({
                        postId,
                        attachmentType: AttachmentCategoryType.DOCUMENT,
                        entityTypes: ['postFilePicker', 'screenFilePicker'],
                        calculateTotalItemsCount: true,
                    }),
                    fromActions.fetchAttachments({
                        postId,
                        attachmentType: AttachmentCategoryType.MULTIMEDIA,
                        entityTypes: ['postFilePicker', 'screenFilePicker'],
                        calculateTotalItemsCount: true,
                    }),
                ]),
            ),
        ),
    );

    fetchFilePickerTemporaryContentViewLink$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.fetchFilePickerTemporaryContentViewLink),
            exhaustMap(({ postId, filePicker, source }) => {
                const entityTypes: AttachmentEntityType[] = [
                    'postFilePicker',
                    'screenFilePicker',
                ];
                const order = getPostAttachmentsOrder(entityTypes);
                const postFilePickerFieldId =
                    filePicker.filePickerMode === 'post'
                        ? filePicker.fieldId
                        : undefined;
                const wfScreenFilePickerFieldId =
                    filePicker.filePickerMode === 'screen'
                        ? filePicker.fieldId
                        : undefined;
                return this.attachmentService
                    .loadPostAttachments(postId, {
                        postFilePickerFieldId,
                        wfScreenFilePickerFieldId,
                        entityTypes,
                        order,
                    })
                    .pipe(
                        expand((res) =>
                            res.pageTokenInfo.tag === 'regularLoading'
                                ? this.attachmentService.loadPostAttachments(
                                      postId,
                                      {
                                          postFilePickerFieldId,
                                          wfScreenFilePickerFieldId,
                                          entityTypes,
                                          nextPageToken:
                                              res.pageTokenInfo.nextPageToken,
                                          order,
                                      },
                                  )
                                : EMPTY,
                        ),
                        map(({ attachments }) =>
                            fromActionsApi.fetchFilePickerTemporaryContentViewLinkSuccess(
                                {
                                    postId,
                                    filePicker,
                                    source,
                                    attachments,
                                },
                            ),
                        ),
                        catchError((error) =>
                            of(
                                fromActionsApi.fetchFilePickerTemporaryContentViewLinkError(
                                    {
                                        error,
                                    },
                                ),
                            ),
                        ),
                    );
            }),
        ),
    );

    exportSurveyPostAnswersCsv$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromActions.exportSurveyPostAnswersCsv),
                exhaustMap(({ postId, postTitle }) => {
                    this.loadingLayerService.open({
                        firstLabel: 'SURVEY.ANSWERS_CSV_LOADING',
                    });
                    return this.surveyTaskService.exportAnswersCsv(postId).pipe(
                        tap(({ blob, filename }) =>
                            downloadBlob(blob, filename || postTitle),
                        ),
                        finalize(() => this.loadingLayerService.close()),
                    );
                }),
            ),
        { dispatch: false },
    );

    requestPruneAllPosts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                fromActionsApi.fetchPostsSuccess,
                fromActionsApi.fetchPostsPageSuccess,
                fromActions.updateAllPosts,
            ),
            concatMap(() =>
                of(null).pipe(
                    withLatestFrom(this.store.select(selectAllFetchInfos)),
                ),
            ),
            map(([_, fetchInfos]) =>
                flatten(
                    Object.values(fetchInfos).map(
                        (fetchInfo) => fetchInfo.fetchOrder,
                    ),
                ),
            ),
            switchMap((localKeepPostIds) =>
                from(
                    this.allPostsExternalReferencesService.getAllExternalReferencedPostsIds(),
                ).pipe(
                    map((externalKeepPostIds) =>
                        unique([...localKeepPostIds, ...externalKeepPostIds]),
                    ),
                ),
            ),
            map((keepPostIds) => fromActions.pruneAllPosts({ keepPostIds })),
        ),
    );

    openLoadingLayerOnWorkflowTransition$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(PostActions.workflowTransition),
                tap(({ transition }) =>
                    this.loadingLayerService.open({
                        firstLabel: 'WORKFLOW.SAVING_TRANSITION.FIRST_LABEL',
                        translateParams: {
                            transitionName: transition.name,
                        },
                    }),
                ),
            ),
        { dispatch: false },
    );

    closeLoadingLayerOnWorkflowTransitionEnd$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    PostActionsApi.workflowTransitionSuccess,
                    PostActionsApi.workflowTransitionError,
                ),
                tap(() => this.loadingLayerService.close()),
            ),
        { dispatch: false },
    );

    fetchPostsCapabilities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PostActions.fetchPostsCapabilities),
            concatMap(({ postIds }) =>
                this.postService.getPostListCapabilities(postIds).pipe(
                    map((capabilities) =>
                        fromActionsApi.fetchPostsCapabilitiesSuccess({
                            postsCapabilities: postIds.reduce(
                                (result, postId, index) => ({
                                    ...result,
                                    [postId]: capabilities[index],
                                }),
                                {},
                            ),
                        }),
                    ),
                    catchError((error) =>
                        of(
                            fromActionsApi.fetchPostsCapabilitiesError({
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    constructor(
        private store: Store<AppState>,
        private actions$: Actions,
        private postService: PostService,
        private eventPostService: EventPostService,
        private customPostService: CustomPostService,
        private surveyPostService: SurveyPostService,
        private surveyTaskService: SurveyTaskService,
        private communitiesStateService: CommunitiesStateService,
        private tipService: TipService,
        private errorService: ErrorService,
        private postListService: PostListService,
        private translationService: CloudTranslationService,
        private filterCommunitiesService: FilterCommunitiesService,
        private stateService: StateService,
        private authService: AuthService,
        private attachmentService: AttachmentService,
        private router: Router,
        private postSelectionService: PostSelectionService,
        private workflowService: WorkflowService,
        private historyRoutingService: HistoryRoutingService,
        private communitiesService: CommunitiesService,
        private catalogsStateService: CatalogsStateService,
        private timeoutsService: TimeoutsService,
        private loadingLayerService: LoadingLayerService,
        private configurationService: ConfigurationService,
        private allPostsExternalReferencesService: AllPostsExternalReferencesService,
    ) {}

    private getPostsCapabilities<T extends IBasePost>(
        posts: T[],
    ): Observable<T[]> {
        return this.postService
            .getPostListCapabilities(posts.map((p) => p.id))
            .pipe(
                map((capabilities) =>
                    zipWith(posts, capabilities, (post, capabilities) => ({
                        ...post,
                        capabilities,
                    })),
                ),
            );
    }

    private getPostListMetadatas(postList: PostList): Observable<PostList> {
        const communityIds = Array.from(
            new Set(postList.list.map((post) => post.communityId)),
        );

        if (communityIds.length) {
            return forkJoin(
                communityIds.map((communityId) =>
                    this.communitiesStateService
                        .getPostMetadata(communityId)
                        .pipe(map((metadata) => ({ communityId, metadata }))),
                ),
            ).pipe(
                map((metadatas) => ({
                    ...postList,
                    list: postList.list.map((post) => ({
                        ...post,
                        metadata: metadatas.find(
                            (mm) => mm.communityId === post.communityId,
                        )?.metadata,
                    })),
                })),
            );
        } else {
            return of(postList);
        }
    }

    private fetchDashboard(filters: IPostFilters): Observable<PostList> {
        const emptyPostList: PostList = {
            list: [],
            totalCount: 0,
            filter: filters,
        };

        // totalItemsCount shold be computed only if filters are not empty and feature flag is enabled
        const _filters: IPostFilters = {
            ...filters,
            calculateTotalItemsCount:
                !filterIsEmpty(filters, false) &&
                !filters.pageToken &&
                this.configurationService.getEnvironmentInfo()
                    ?.installedFeatures.calculateTotalItemsCount ===
                    CalculateItemsCount.ON,
        };
        return iif(
            () =>
                filters.communityId !== null &&
                filters.communityId !== undefined,
            this.postListService.communityList({
                ..._filters,
                pinnedFirst:
                    !filters.order?.orderBy ||
                    filters.order.orderBy ===
                        PostOrderType.LAST_MODIFY_AND_COMMENT_TIMESTAMP,
            }),
            this.postListService.globalList(_filters, undefined, true, true),
        ).pipe(
            mergeMap((postList) =>
                this.getPostsCapabilities(postList.list).pipe(
                    map((posts) => ({
                        ...postList,
                        list: posts,
                    })),
                ),
            ),
            mergeMap((postList) => this.getPostListMetadatas(postList)),
            defaultIfEmpty(emptyPostList),
            catchError((error) => {
                if (error.status === 404) {
                    return of(emptyPostList);
                } else {
                    return throwError(error);
                }
            }),
        );
    }

    private fetchDetail(
        postId: number,
        skipMentions?: boolean,
    ): Observable<IBasePost> {
        return this.postService.getPostDetail(postId, true, skipMentions);
    }

    private fetchPosts(
        fetchType: PostFetch,
        filters: IPostFilters = emptyPostFilters(),
        postId?: number,
        skipMentions?: boolean,
    ): Observable<PostList> {
        switch (fetchType) {
            case 'dashboard':
                return this.fetchDashboard(filters);
            case 'detail':
                if (postId != null) {
                    return this.fetchDetail(postId, skipMentions).pipe(
                        map((post) => ({
                            list: [post],
                            filter: {} as IPostFilters,
                        })),
                    );
                } else {
                    throw new Error(
                        `fetchPosts: postId must be defined for fetchType ${fetchType}`,
                    );
                }
            default:
                assertUnreachable(fetchType);
        }
    }

    private fetchPostsCount(
        filters: IPostFilters = emptyPostFilters(),
    ): Observable<number> {
        const noResults = 0;
        const _filters: IPostFilters = {
            ...filters,
            calculateTotalItemsCount: true,
            pageSize: 0,
        };

        return iif(
            () =>
                filters.communityId !== null &&
                filters.communityId !== undefined,
            this.postListService.communityList(_filters, false, false),
            this.postListService.globalList(_filters, undefined, false, true),
        ).pipe(
            map((postList) => postList?.totalCount ?? noResults),
            catchError((error) => {
                if (error.status === 404) {
                    return of(noResults);
                } else {
                    return throwError(error);
                }
            }),
        );
    }

    private isHomeOrPostView(
        communityId: number | null | undefined,
        communitiesUI: CacheMap<CommunityUI> | undefined,
    ): boolean {
        return (
            !isDefined(communityId) ||
            (isDefined(communitiesUI) &&
                communitiesUI[communityId]?.dashboardView !==
                    DashboardView.Attachments)
        );
    }

    private getCreatePostApi(
        community: ICommunity,
        postCreate: GenericPostCreate,
    ): Observable<GenericPost> {
        let api$: Observable<GenericPost>;

        switch (postCreate.type) {
            case PostType.CUSTOM:
                api$ = this.customPostService.createPost(
                    community.id,
                    postCreate as ICustomPostCreate,
                    community.metadata,
                );
                break;
            case PostType.EVENT:
                api$ = this.eventPostService.createPost(
                    community.id,
                    postCreate as IEventPostCreate,
                );
                break;
            case PostType.SURVEY:
                api$ = this.surveyPostService.createPost(
                    community.id,
                    postCreate as SurveyPostCreate,
                );
                break;
            default:
                assertUnreachable(postCreate.type);
        }

        return api$;
    }

    private getEditPostApi(
        community: ICommunity,
        postEdit: IBasePostEdit,
    ): Observable<GenericPost> {
        let api$: Observable<GenericPost>;

        switch (postEdit.type) {
            case PostType.CUSTOM:
                api$ = this.customPostService.updatePost(
                    postEdit as ICustomPostEdit,
                    community.metadata!,
                    false,
                );
                break;
            case PostType.EVENT:
                api$ = this.eventPostService.updatePost(
                    postEdit as IEventPostEdit,
                    community.metadata!,
                );
                break;
            case PostType.SURVEY:
                api$ = this.surveyPostService.updatePost(
                    postEdit as SurveyPostEdit,
                    community.metadata!,
                );
                break;
            default:
                assertUnreachable(postEdit.type);
        }

        return api$;
    }

    private getCopyPostApi(
        originalPostId: number,
        postCopy: IBasePostCreate,
        metadata: IPostMetadata,
    ): Observable<GenericPost> {
        let api$: Observable<GenericPost>;

        switch (postCopy.type) {
            case PostType.CUSTOM:
                api$ = this.customPostService.copyPost(
                    originalPostId,
                    postCopy as ICustomPostEdit,
                    metadata,
                );
                break;
            case PostType.EVENT:
                api$ = this.eventPostService.copyPost(
                    originalPostId,
                    postCopy as IEventPostEdit,
                    metadata,
                );
                break;
            case PostType.SURVEY:
                api$ = this.surveyPostService.copyPost(
                    originalPostId,
                    postCopy as SurveyPostEdit,
                    metadata,
                );
                break;
            default:
                assertUnreachable(postCopy.type);
        }

        return api$;
    }
}
