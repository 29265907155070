import { Injectable } from '@angular/core';
import { AuthService } from '@interacta-shared/data-access-auth';
import { filterMap, isDefined } from '@interacta-shared/util';
import {
    differentialDetailBaseRoute,
    digitalWorkplace,
} from '@modules/app-routing/routes';
import { AppSelectors } from '@modules/core/store';
import { isEventPost } from '@modules/post/models/event-post.model';
import { filterIsEmpty } from '@modules/post/models/filter-post/filter-post.utils';
import { isSurveyPost } from '@modules/post/models/survey-post/survey-post.utils';
import { PostListService } from '@modules/post/services/post-list.service';
import { PostService } from '@modules/post/services/post.service';
import { CommunitiesStateService } from '@modules/state/services/communities-state.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { combineLatest, iif, merge, of } from 'rxjs';
import {
    catchError,
    concatMap,
    filter,
    first,
    map,
    mergeMap,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import * as postApi from '../post/post-api.actions';
import * as postActions from '../post/post.actions';
import { AppState } from '../post/post.reducer';
import {
    selectCurrentFilters,
    selectDetail,
    selectFetchInfo,
    selectPostById,
    selectSyncToken,
} from '../post/post.selectors';
import * as actions from './differential.actions';
import {
    selectAreThereDiffPosts,
    selectDifferentialPostDetail,
} from './differential.selectors';

@Injectable()
export class DifferentialEffects {
    initSyncToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.initSyncToken),
            switchMap((_) =>
                this.postListService.globalStream().pipe(
                    map(({ nextSyncToken }) =>
                        actions.initSyncTokenSuccess({
                            syncToken: nextSyncToken,
                        }),
                    ),
                    catchError((error) =>
                        of(actions.initSyncTokenError({ error })),
                    ),
                ),
            ),
        ),
    );

    // silent errors
    differentialError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    actions.initSyncTokenError,
                    actions.fetchDifferentialError,
                ),
                tap((action) => {
                    console.error(action);
                }),
            ),
        { dispatch: false },
    );

    fetchDifferential$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.fetchDifferential),
            withLatestFrom(this.store.select(selectSyncToken)),
            filterMap(([_, syncToken]) => (syncToken ? syncToken : undefined)),
            switchMap((syncToken) =>
                this.postListService.globalStream(syncToken).pipe(
                    map(({ nextSyncToken, posts }) =>
                        actions.fetchDifferentialSuccess({
                            syncToken: nextSyncToken,
                            posts,
                        }),
                    ),
                    catchError((error) =>
                        of(actions.fetchDifferentialError({ error })),
                    ),
                ),
            ),
        ),
    );

    handleDifferential$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.fetchDifferentialSuccess),
            concatMap((action) => action.posts),
            map((post) => ({
                post,
                currentUserId: this.authService.currentUserData()?.id,
            })),
            filterMap((x) => (x.currentUserId != null ? x : undefined)),
            mergeMap(({ post, currentUserId }) =>
                of({ post, currentUserId }).pipe(
                    withLatestFrom(
                        combineLatest([
                            this.store.select(
                                selectCurrentFilters('dashboard'),
                            ),
                            this.store.select(selectFetchInfo('dashboard')),
                            this.store.select(AppSelectors.selectRouteState),
                            this.store.select(selectPostById(post.id)),
                            this.store.select(selectDetail),
                        ]),
                        this.communitiesStateService.state.communitiesVisibleInDashboard$.pipe(
                            map((communities) =>
                                communities.map((community) => community.id),
                            ),
                        ),
                    ),
                ),
            ),
            map(
                ([
                    { post, currentUserId },
                    [filters, fetchInfo, route, prevPost, postDetail],
                    communityIds,
                ]) => {
                    if (
                        post.status === 'CREATED' &&
                        post.lastModifyUser?.id === currentUserId
                    ) {
                        // When the post is created by the current user, consider it TOUCHED
                        post.status = 'TOUCHED';
                    }
                    return {
                        post,
                        currentUserId,
                        filters,
                        fetchInfo,
                        route,
                        prevPost,
                        postDetail,
                        communityIds,
                    };
                },
            ),
            filter(
                ({
                    post,
                    currentUserId,
                    filters,
                    fetchInfo,
                    route,
                    prevPost,
                    postDetail,
                    communityIds,
                }) => {
                    if (isDefined(route)) {
                        const isFilterEmpty = filterIsEmpty(filters, false);
                        // When the status isn't TOUCHED, we filter out the changes by the current user

                        const touchedOrUpdatedByAnotherOne =
                            post.status === 'TOUCHED' ||
                            post.lastModifyUser?.id != currentUserId;

                        // CASE 1: if current base route is post-detail
                        // We always handle diff related to current post-detail
                        const isCase1 =
                            differentialDetailBaseRoute.includes(
                                route.appBaseRoute,
                            ) && postDetail?.id === post.id;

                        // CASE 2: if current base route is other than post-detail (or diff doesn't affect current post-detail)
                        const isCase2 =
                            // When custom filters or sorting are set, we filter out the changes to unknown posts
                            (isFilterEmpty || !!prevPost) &&
                            // When the user is not in a community, we filter out the changes to posts outside communities non included in home
                            // When the user is in a community, we filter out the changes to posts outside that community
                            ((!isDefined(filters.communityId) &&
                                communityIds.includes(post.communityId)) ||
                                filters.communityId === post.communityId) &&
                            // When the status isn't CREATE or DELETED, we ensure that we are displaying the post
                            (post.status === 'CREATED' ||
                                post.status === 'DELETED' ||
                                fetchInfo.fetchOrder.includes(post.id));

                        const isHomePage =
                            (post.status === 'TOUCHED' ||
                                post.status === 'MODIFIED') &&
                            route.appBaseRoute === digitalWorkplace;

                        return (
                            touchedOrUpdatedByAnotherOne &&
                            (isCase1 || isCase2 || isHomePage)
                        );
                    } else {
                        return false;
                    }
                },
            ),

            // In some cases we need to enrich the post with more
            // attributes (e.g: capabilities & metadata) from the postDetail API
            mergeMap(({ post, currentUserId, route, fetchInfo, postDetail }) =>
                iif(
                    () =>
                        ['CREATED', 'TOUCHED', 'MODIFIED'].includes(
                            post.status,
                        ),
                    post.status == 'CREATED'
                        ? this.postService.getPostDetail(post.id, true).pipe(
                              map((postDetail) => ({
                                  ...post,
                                  ...postDetail,
                              })),
                          )
                        : this.postService.getPostCapabilities(post.id).pipe(
                              map((capabilities) => ({
                                  ...post,
                                  capabilities,
                              })),
                          ),
                    of(post),
                ).pipe(
                    map((enrichedPost) => {
                        if (
                            route &&
                            differentialDetailBaseRoute.includes(
                                route.appBaseRoute,
                            ) &&
                            postDetail.id === enrichedPost.id
                        ) {
                            // CASE 1: current base route is post-detail and diff is related to current post-detail
                            return actions.mergePartiallyInDetail({
                                post: enrichedPost,
                                addToDiffPostDetail:
                                    enrichedPost.status === 'MODIFIED',
                            });
                        } else {
                            // CASE 2: current base route is other than post-detail (or diff doesn't affect current post-detail)
                            return enrichedPost.status === 'CREATED'
                                ? fetchInfo.fetchOrder.includes(post.id)
                                    ? undefined // We exclude CREATED diff related to post we already known
                                    : actions.addToDiffPosts({
                                          post: enrichedPost,
                                          currentUserId: currentUserId ?? -1,
                                      })
                                : enrichedPost.status === 'DELETED'
                                  ? actions.removeFromDiffPosts({
                                        post: enrichedPost,
                                    })
                                  : actions.mergePartiallyDashboardOrHome({
                                        post: enrichedPost,
                                    });
                        }
                    }),
                    filterMap((action) => action),
                ),
            ),
        ),
    );

    discardDiffPostsOnFilterChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                postApi.changeFiltersSuccess,
                postActions.fetchPostsWithCurrentFilters,
            ),
            withLatestFrom(this.store.select(selectAreThereDiffPosts)),
            filter(([_, areThereDiffPosts]) => areThereDiffPosts),
            map(actions.discardDiffPosts),
        ),
    );

    discardDiffPostDetailOnFetchPost$ = createEffect(() =>
        this.actions$.pipe(
            ofType(postApi.fetchPostsSuccess),
            withLatestFrom(this.store.select(selectDifferentialPostDetail)),
            filter(
                ([action, diffPostDetail]) =>
                    diffPostDetail != null &&
                    action.postList.list
                        .map((post) => post.id)
                        .includes(diffPostDetail.id),
            ),
            map(actions.discardDiffPostDetail),
        ),
    );

    mergeDiffPostDetailOnNavigateToDashboard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(routerNavigatedAction),
            concatMap(() =>
                this.store.select(AppSelectors.selectRouteState).pipe(first()),
            ),
            filter((route) => route?.appBaseRoute === 'dashboard'),
            withLatestFrom(this.store.select(selectDifferentialPostDetail)),
            filterMap(([_, diffPostDetail]) => diffPostDetail ?? undefined),
            map((diffPostDetail) =>
                actions.mergePartiallyDashboardOrHome({ post: diffPostDetail }),
            ),
        ),
    );

    updateSurveyQuestionsDefinitionOnMergeDetail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.mergeDiffPostDetail),
            map(({ post }) => [post]),
            map((posts) =>
                postActions.updateSurveyQuestionsDefinitionPosts({
                    posts,
                }),
            ),
        ),
    );

    setSurveyExpirationTimeoutOnMergeDiffPosts$ = createEffect(() =>
        merge(
            this.actions$.pipe(ofType(actions.mergeDiffPosts)),
            this.actions$.pipe(
                ofType(
                    actions.mergePartiallyDashboardOrHome,
                    actions.mergePartiallyInDetail,
                    actions.mergeDiffPostDetail,
                ),
                map(({ post }) => ({ posts: [post] })),
            ),
        ).pipe(
            mergeMap(({ posts }) => posts),
            filterMap((post) =>
                isSurveyPost(post) || isEventPost(post) ? post : undefined,
            ),
            map((post) =>
                postActions.setTimeRelatedStateUpdateTimeout({ post }),
            ),
        ),
    );

    updateSurveyQuestionsDefinitionOnMergePartially$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                actions.mergePartiallyDashboardOrHome,
                actions.mergePartiallyInDetail,
            ),

            map(({ post }) =>
                postActions.updateSurveyQuestionsDefinitionPosts({
                    posts: [post],
                }),
            ),
        ),
    );

    constructor(
        private store: Store<AppState>,
        private actions$: Actions,
        private postService: PostService,
        private postListService: PostListService,
        private authService: AuthService,
        private communitiesStateService: CommunitiesStateService,
    ) {}
}
